div.rotate {
  white-space: nowrap;
}

div.rotate > div {
  transform: translate(-15px, 1px) rotate(305deg);
}
div.rotate > div > span {
  padding: 5px 10px;
}

.right-justify {
  width: 100%;
  & > span {
    justify-content: flex-end;
  }
}
import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {MatTabsModule} from '@angular/material/tabs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-rwe-forestplot-grid',
  templateUrl: './rwe-forestplot-grid.component.html',
  styleUrls: ['./rwe-forestplot-grid.component.scss']
})
export class RWEForestPlotGridComponent implements OnInit {
  public activeTab: 'tabular' | 'forestplot' = 'tabular'; // Default to 'tabular'

  constructor(private titleService: Title, private router: Router) {}

  ngOnInit(): void {
    this.titleService.setTitle('Real-World Evidence with Forest Plot');
    this.initializeActiveTab();
  }

  initializeActiveTab(): void {
    const currentUrl = this.router.url;
    if (currentUrl.includes('tool')) {
      this.activeTab = 'forestplot';
    } else {
      this.activeTab = 'tabular';
    }
  }

  setActiveTab(tab: 'tabular' | 'forestplot'): void {
    this.activeTab = tab;
  }
}

import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {VariantsMutationsQuery} from '../../models/dtos/trace/variants-mutations-ql';

@Injectable({
  providedIn: 'root'
})
export class VariantMutationsQL extends Query<VariantsMutationsQuery, {}> {
  override document = gql`
    {
      tracemutations {
        id
        assayType
        variantLineage
        variantWhoName
        variantMutationSpike
        variantMutationNonspike
        variantSource
        variantIsolate
        variantPassageHistory
        variantTiter
        variantRefStrain
      }
    }
  `;
}

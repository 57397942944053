import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {AssayOverviewsQuery} from '../models/dtos/assay/assay-overviews-ql';

@Injectable({
  providedIn: 'root'
})
export class AssayOverviewsQL extends Query<AssayOverviewsQuery, {}> {
  override document = gql`
    query getVariant3AssayOverviewAll {
      variant3assayoverviews {
        id
        assayType
        viralAgents
        experimentalMethodology
        assayBackground
        assayHowitworks
        assayInterpretation
        assayLimitations
        assayFigureLegend
        assayIcon
        assayFigure
        dateUpdated
      }
    }
  `;
}

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {map, Observable, throwError} from 'rxjs';
import {InVivoPoint} from '../../../models/dtos/in-vivo/invivo';
import {API_URLS, Endpoints} from '@odp/shared';
import {ApolloQueryResult, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {Apollo} from 'apollo-angular';
import {GRAPHQL_ENDPOINT_KEY} from '../../../constants/api-constants';
import {InVivoNode, InVivoQuery} from '../../../models/dtos/in-vivo/invivo-ql';
import {InVivoQL} from '../../../queries/invivo-query';

@Injectable({
  providedIn: 'root'
})
export class InVivoApiService {
  private variantBaseUrl!: string;

  configService: any;
  constructor(
    private httpClient: HttpClient,
    httpLink: HttpLink,
    @Inject(API_URLS) private configuration: Endpoints,
    private apollo: Apollo,
    private invivoQL: InVivoQL
  ) {
    this.variantBaseUrl = configuration.graphqlEndpoint; // hit the postgres endpoint

    const uri = this.configuration.graphqlEndpoint;
    if (!apollo.use(GRAPHQL_ENDPOINT_KEY)) {
      apollo.createNamed(GRAPHQL_ENDPOINT_KEY, {
        link: httpLink.create({uri}),
        cache: new InMemoryCache()
      });
    }
  }

  public getInVivoPoints(): Observable<InVivoPoint[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<InVivoQuery>({query: this.invivoQL.document})
      .pipe(
        map((result: ApolloQueryResult<InVivoQuery>) => {
          if (!!result && !!result.errors && result.errors.length > 0) {
            throw throwError(() => new Error('GraphQL errors: ' + result.errors!.map((e) => e.message).join(', ')));
          }
          return this.mapInVivoToEntry(result.data.variantinvivos);
        })
      );
  }

  public mapInVivoToEntry(invivoEntry: InVivoNode[]): InVivoPoint[] {
    const entries = invivoEntry.map((e) => {
      const invivo: InVivoPoint = {
        reportNumber: String(Math.round(Number(e.reportNumber))),
        dataSource: e.dataSource,
        provider: e.provider,
        title: e.title,
        reportedDate: e.reportedDate,
        dataUploadedDate: e.dataUploadedDate,
        studyType: e.studyType,
        therapeuticClasses: e.therapeuticClasses,
        therapeuticAgents: e.therapeuticAgents,
        model: e.model,
        modelStrain: e.modelStrain,
        modelSource: e.modelSource,
        therapeutic1Class: e.therapeutic1Class,
        therapeutic1: e.therapeutic1,
        administrationMethod1: e.administrationMethod1,
        dose1: e.dose1,
        regimen1: e.regimen1,
        treatment1Notes: e.treatment1Notes,
        therapeutic1Details: e.therapeutic1Details,
        therapeutic2Class: e.therapeutic2Class,
        therapeutic2: e.therapeutic2,
        administrationMethod2: e.administrationMethod2,
        dose2: e.dose2,
        regimen2: e.regimen2,
        treatment2Notes: e.treatment2Notes,
        therapeutic2Details: e.therapeutic2Details,
        variants: e.variants,
        variantModifications: e.variantModifications,
        whoDesignation: e.whoDesignation,
        referenceStrain: e.referenceStrain,
        inoculationMethod: e.inoculationMethod,
        inoculationDose: e.inoculationDose,
        challengeNotes: e.challengeNotes,
        challengeDetails: e.challengeDetails,
        clinicalManifestastions: e.clinicalManifestastions,
        viralLoad: e.viralLoad,
        histopathology: e.histopathology,
        neutralization: e.neutralization,
        antibodyResponse: e.antibodyResponse,
        otherDatabaseReportNumber: e.otherDatabaseReportNumber,
        animalModelsLinkedPage: e.animalModelsLinkedPage,
        dataUpdatedDate: e.dataUpdatedDate,
        transmission: e.transmission,
        rechallenge: e.rechallenge,
        id: ''
      };
      return invivo;
    });
    return entries;
  }
}

.d3-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;  /* Take full width */
  margin: 0;
  padding-left: 10px; /* Adjust as needed */
  padding-right: 10px; /* Adjust for right side gap */
}

.ag-header-cell-resize {
  width: 0 !important;
}


.header-text {
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}



.d3-axis {
  width: 100%;
  height: 30px; /* Reduced height to fit the axis */
  display: flex;
  justify-content: center;
  margin: 0;  /* Remove extra margin */
  padding: 0; /* Remove padding */
  margin-top: -5px; /* Adjust as needed to bring axis closer to the title */
}




/* Override the left padding for the Forest plot column */
.ag-theme-alpine .ag-header-cell.no-left-padding,
.ag-theme-alpine .ag-header-group-cell.no-left-padding {
  padding-left: 0 !important; /* Override the left padding */
  padding-right: 0 !important; /* Override the right padding */
  margin: 0 !important;
}

/* Ensure that the no-left-padding class has higher specificity */
.ag-theme-alpine .ag-header-cell.no-left-padding .ag-header-cell-label,
.ag-theme-alpine .ag-header-group-cell.no-left-padding .ag-header-cell-label {
  padding-left: 0 !important; /* Override the left padding */
  padding-right: 0 !important; /* Override the right padding */
  margin: 0 !important;
}

/* Add higher specificity by chaining the class more deeply */
.ag-theme-alpine .ag-header-cell.no-left-padding .ag-header-cell-label .ag-header-cell-comp-wrapper,
.ag-theme-alpine .ag-header-group-cell.no-left-padding .ag-header-cell-label .ag-header-cell-comp-wrapper {
  padding-left: 0 !important; /* Ensure padding is overridden */
  padding-right: 0 !important; /* Ensure padding is overridden */
  margin: 0 !important;
}

.ag-header-cell-comp-wrapper {
  padding: 0 !important;
  margin: 0 !important;
}

.ag-header-cell-comp-wrapper > app-d3-header {
  width: 100% !important;
}

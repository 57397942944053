import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {AssayOverviewsQuery} from '../../models/dtos/trace/assay-overviews-ql';

@Injectable({
  providedIn: 'root'
})
export class AssayOverviewsQL extends Query<AssayOverviewsQuery, {}> {
  override document = gql`
    query assay {
      traceassays(assayShowHide: 1) {
        assayId
        assayType
        assayCategory
        assayTarget
        assayOverview
        detectionType
        cellLine1
        cellLine2
        cellLine3
        interpretationNotesLink
        protocolLink
      }
    }
  `;
}

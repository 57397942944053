import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {DownloadFilesQuery} from '../models/dtos/download-files/downloadfiles-ql';

@Injectable({
  providedIn: 'root'
})
export class DownloadFilesQL extends Query<DownloadFilesQuery, {}> {
  // Query to get all files
  override document = gql`
    {
      getAllFiles {
        id
        name
        path
      }
    }
  `;

  // Query to get a file by name
  static getFile = gql`
    query getFile($fileName: String!) {
      getFile(name: $fileName) {
        id
        name
        path
      }
    }
  `;
}

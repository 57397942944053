<div class="rweforestplot-page">
  <div class="filter-left">
    <app-rwe-forestplot-filters
      (filtersChanged)="filterTable($event)"
      [activeTab]="activeTab"
    ></app-rwe-forestplot-filters>
  </div>
  <div class="col-9 main-content">
    <div class="total">{{ rowCount }} {{ rowCount === 1 ? ' entry found' : 'entries found' }}</div>
    <div class="ag-grid-container">
      <ag-grid-angular
        style="width: 100%; height: 1000px"
        class="ag-theme-alpine"
        id="rweGrid"
        [rowData]="selectedRWEPoints"
        [columnDefs]="columnDefs"
        [components]="frameworkComponents"
        [defaultColDef]="defaultColDef"
        [gridOptions]="gridOptions"
        [pagination]="true"
        [paginationPageSize]="20"
        [headerHeight]="48"
        (filterChanged)="filterChanged($event)"
        [tooltipShowDelay]="500"
      ></ag-grid-angular>
    </div>
    <!-- disable this per new requirements -->
    <!-- <div class="d3-chart-container">
      <app-d3-bar-chart [data]="filteredData"></app-d3-bar-chart>
    </div> -->
  </div>
</div>

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BivalentBoosterQL} from '../../../queries/bivalentbooster-query';
import {map, Observable} from 'rxjs';
import {BivalentBoosterPoint} from '../../../models/dtos/bivalent-booster/bivalentbooster';
import {API_URLS, Endpoints} from '@odp/shared';
import {HttpLink} from 'apollo-angular/http';
import {Apollo} from 'apollo-angular';
import {GRAPHQL_ENDPOINT_KEY} from '../../../constants/api-constants';
import {InMemoryCache} from '@apollo/client/core';
import {BivalentBoosterResult, BivalentBoosterQuery} from '../../../models/dtos/bivalent-booster/bivalentbooster-ql';

@Injectable({
  providedIn: 'root'
})
export class BivalentBoosterApiService {
  private variantBaseUrl!: string;
  configService: any;

  constructor(
    private httpClient: HttpClient,
    httpLink: HttpLink,
    @Inject(API_URLS) private configuration: Endpoints,
    private apollo: Apollo,
    private bivalentBoosterQL: BivalentBoosterQL
  ) {
    this.variantBaseUrl = configuration.graphqlEndpoint;
    const uri = this.configuration.graphqlEndpoint;
  }

  public getBivalentBooster(): Observable<BivalentBoosterPoint[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<BivalentBoosterQuery>({query: this.bivalentBoosterQL.document})
      .pipe(
        map((result: {data: BivalentBoosterQuery}) => {
          return this.mapBivalentBoosterToEntryWithEdge(result.data.bivalentboosters);
        })
      );
  }

  public getBivalentBoosterByBoostGlossId(boostGlossid: string): Observable<BivalentBoosterPoint[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<BivalentBoosterQuery>({
        query: this.bivalentBoosterQL.getBivalentBoostersByboostGlossid,
        variables: {boostGlossid: boostGlossid}
      })
      .pipe(
        map((result: {data: BivalentBoosterQuery}) => {
          return this.mapBivalentBoosterToEntry(result.data.bivalentboosters);
        })
      );
  }

  public mapBivalentBoosterToEntry(bivalentEntry: BivalentBoosterResult[]): BivalentBoosterPoint[] {
    const enteries = bivalentEntry.map((e) => {
      const bivalent: BivalentBoosterPoint = {
        mvbReportNumber: Math.round(Number(e.mvbReportNumber)),
        dataTitle: e.dataTitle,
        dataSource: e.dataSource,
        dataDate: e.dataDate,
        sampleSource: e.sampleSource,
        dataSourceType: e.dataSourceType,
        infectionHistory: e.infectionHistory,
        vacHistory: e.vacHistory,
        vacBoost: e.vacBoost,
        boostGlossid: e.boostGlossid,
        boostClass: e.boostClass,
        assayCat: e.assayCat,
        assayType: e.assayType,
        assayTypeMod: e.assayTypeMod,
        viralLineage: e.viralLineage,
        vaccineActivity: e.vaccineActivity,
        boost1TimeUnit: e.boost1TimeUnit,
        boost1Time1: e.boost1Time1,
        boost1Time1Unit: e.boost1Time1 ? `${e.boost1Time1} ${e.boost1TimeUnit}` : '',
        boost1Effect1: e.boost1Effect1,
        boost1Time2: e.boost1Time2,
        boost1Time2Unit: e.boost1Time2 ? `${e.boost1Time2} ${e.boost1TimeUnit}` : '',
        boost1Effect2: e.boost1Effect2,
        boost1Time3: e.boost1Time3,
        boost1Time3Unit: e.boost1Time3 ? `${e.boost1Time3} ${e.boost1TimeUnit}` : '',
        boost1Effect3: e.boost1Effect3
      };
      return bivalent;
    });
    return enteries;
  }

  public mapBivalentBoosterToEntryWithEdge(bivalentEntry: BivalentBoosterResult[]): BivalentBoosterPoint[] {
    const enteries = bivalentEntry.map((e) => {
      const bivalent: BivalentBoosterPoint = {
        mvbReportNumber: Math.round(Number(e.mvbReportNumber)),
        dataTitle: e.dataTitle,
        dataSource: e.dataSource,
        dataDate: e.dataDate,
        sampleSource: e.sampleSource,
        dataSourceType: e.dataSourceType,
        infectionHistory: e.infectionHistory,
        vacHistory: e.vacHistory,
        vacBoost: e.vacBoost,
        boostGlossid: e.boostGlossid,
        boostClass: e.boostClass,
        assayCat: e.assayCat,
        assayType: e.assayType,
        assayTypeMod: e.assayTypeMod,
        viralLineage: e.viralLineage,
        vaccineActivity: e.vaccineActivity,
        boost1TimeUnit: e.boost1TimeUnit,
        boost1Time1: e.boost1Time1,
        boost1Time1Unit: e.boost1Time1 ? `${e.boost1Time1} ${e.boost1TimeUnit}` : '',
        boost1Effect1: e.boost1Effect1,
        boost1Time2: e.boost1Time2,
        boost1Time2Unit: e.boost1Time2 ? `${e.boost1Time2} ${e.boost1TimeUnit}` : '',
        boost1Effect2: e.boost1Effect2,
        boost1Time3: e.boost1Time3,
        boost1Time3Unit: e.boost1Time3 ? `${e.boost1Time3} ${e.boost1TimeUnit}` : '',
        boost1Effect3: e.boost1Effect3
      };
      return bivalent;
    });
    return enteries;
  }
}

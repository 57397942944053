import {Component, OnInit, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  GridOptions,
  GridReadyEvent,
  AgGridEvent,
  ValueFormatterParams,
  ITooltipParams,
  ICellRendererParams,
  IRowNode,
  RowHeightParams
} from 'ag-grid-community';
import {forkJoin} from 'rxjs';
import {RWEForestPlotPoint} from '../../../models/dtos/rweforestplot/rweforestplot';
import {RWEForestPlotApiService} from '../../../services/api/rweforestplot-api/rweforestplot-api.service';
import {RWEForestPlotFilterService} from '../rwe-forestplot-filter/rwe-forestplot-filter.service';
import {D3CellRendererComponent} from '../d3-cell-renderer/d3-cell-renderer.component';
import {RWEForestPlotAxisHeaderComponent} from '../rwe-forestplot-axis-header/rwe-forestplot-axis-header.component';
import {SelectedRWEFilters} from '../rwe-forestplot-filters/rwe-forestplot-filters.component';
import {ICustomHeaderParams} from '../rwe-forestplot-axis-header/custom-header-params.interface';
import {DatasourceRendererComponent} from '../datasource-renderer/datasource-renderer.component';
import {RWEForestPlotReportTooltipComponent} from '../rwe-forestplot-report-tooltip/rwe-forestplot-report-tooltip.component';

@Component({
  selector: 'app-rwe-forestplot-tool',
  templateUrl: './rwe-forestplot-tool.component.html',
  styleUrls: ['./rwe-forestplot-tool.component.scss']
})
export class RWEForestPlotToolComponent implements OnInit {
  public activeTab!: 'tabular' | 'forestplot';
  public gridOptions: GridOptions;
  public allRWEPoints!: RWEForestPlotPoint[];
  public selectedRWEPoints!: RWEForestPlotPoint[];
  public frameworkComponents: any;
  public defaultColDef: any;
  public columnDefs: any;
  public rowCount!: number;
  public totalRows!: number;
  public globalMinCI = 0.01; // Set your globalMinCI value
  public globalMaxCI = 100; // Set your globalMaxCI value
  public metricColorMap: any;
  public filteredData: {study_country: string; covidenceNb: string}[] = [];

  constructor(
    private variantApi: RWEForestPlotApiService,
    private RWEFilters: RWEForestPlotFilterService,
    private route: ActivatedRoute
  ) {
    this.frameworkComponents = {
      d3CellRenderer: D3CellRendererComponent,
      d3Header: RWEForestPlotAxisHeaderComponent,
      datasourceRenderer: DatasourceRendererComponent,
      customTooltip: RWEForestPlotReportTooltipComponent
    };

    this.defaultColDef = {
      width: 200,
      filter: true,
      sortable: true,
      resizable: true,
      cellStyle: {'border-bottom': '1px solid #ddd'}
    };

    this.metricColorMap = {
      'Hazard Ratio': '#0072b2',
      'Adjusted Hazard Ratio': '#0072b2',
      'Unadjusted Hazard Ratio': '#0072b2',
      'Adjusted Odds Ratio': '#fbb732',
      'Odds Ratio': '#fbb732',
      'Odds Ratio Estimate': '#fbb732',
      'Adjusted Risk Ratio': '#63bd59',
      'Recovery Rate Ratio': '#63bd59',
      'Recovery Rate Ratio or Hazard Ratio': '#63bd59',
      'Absolute Risk Difference': '#cc3794',
      'Difference (+SE) from Placebo': '#cc3794',
      'Difference from Placebo': '#cc3794'
    };

    this.columnDefs = [
      {
        field: 'firstAuthor',
        headerName: 'Authors',
        width: 130,
        sortable: true,
        resizable: true,
        tooltipField: 'firstAuthor',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'treatmentsCombined',
        headerName: 'Treatment (n)',
        width: 260,
        cellStyle: {'text-align': 'left'},
        tooltipField: 'treatmentsCompiledEdited',
        tooltipComponent: 'customTooltip',
        valueGetter: (params: ValueFormatterParams) => {
          const treatments = [];
          if (params.data.drug1 && params.data.drug1N) {
            treatments.push(`${params.data.drug1} (${params.data.drug1N})`);
          }
          if (params.data.drug2 && params.data.drug2N) {
            treatments.push(`${params.data.drug2} (${params.data.drug2N})`);
          }
          if (params.data.controlN) {
            treatments.push(`Control (${params.data.controlN})`);
          } else {
            treatments.push(`Control (N/R)`);
          }
          return treatments.length > 0 ? treatments.join('; ') : 'N/R';
        }
      },
      {
        field: 'endpoint',
        headerName: 'Endpoint',
        width: 120,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'}
      },
      {
        field: 'outcomeMetricType',
        headerName: 'Metric used',
        width: 200,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        valueGetter: (params: ValueFormatterParams) => params.data.outcomeMetricType,
        cellRenderer: (params: ValueFormatterParams) => {
          const metricType = params.value;
          const color = this.metricColorMap[metricType as keyof typeof this.metricColorMap] || '#000000'; // Fallback to black if no match

          // Create the colored circle with text
          const html = `
            <span style="display: flex; align-items: center;">
              <span style="width: 15px; height: 15px; border-radius: 50%; background-color: ${color}; margin-right: 5px;"></span>
              ${metricType}
            </span>
          `;

          // Use innerHTML to render the circle and text
          const div = document.createElement('div');
          div.innerHTML = html;
          return div;
        }
      },
      {
        field: 'outcomeMetricPvalue',
        headerName: 'Metric (95% CI), p-value',
        width: 200,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        tooltipComponent: 'customTooltip',
        tooltipValueGetter: (params: ITooltipParams) => {
          const data = params.data;
          if (!data.outcomeMetric) {
            return 'N/A';
          }
          const parts = [];
          const outcomeMetricDetails =
            data.outcomeMetricLowerCi !== undefined && data.outcomeMetricUpperCi !== undefined
              ? `${data.outcomeMetric} (${data.outcomeMetricLowerCi} - ${data.outcomeMetricUpperCi})`
              : data.outcomeMetric;
          parts.push(`${outcomeMetricDetails}; ${data.outcomeMetricPValue || 'N/R'}`);
          return parts.join(' ').trim();
        },
        valueGetter: (params: ValueFormatterParams) => {
          const data = params.data;
          if (!data.outcomeMetric) {
            return 'N/A';
          }
          const parts = [];
          const outcomeMetricDetails =
            data.outcomeMetricLowerCi !== undefined && data.outcomeMetricUpperCi !== undefined
              ? `${data.outcomeMetric} (${data.outcomeMetricLowerCi} - ${data.outcomeMetricUpperCi})`
              : data.outcomeMetric;
          parts.push(`${outcomeMetricDetails}; ${data.outcomeMetricPValue || 'N/R'}`);
          return parts.join(' ').trim();
        }
      },
      // THESE ARE JUST FOR TESTING FOREST PLOT - these can be removed before release (TT)
      // {
      //   field: 'outcomeMetricLowerCi',
      //   headerName: 'outcomeLowerCi',
      //   width: 140,
      //   filter: true,
      //   resizable: true,
      //   cellStyle: {'text-align': 'left'}
      // },
      // {
      //   field: 'outcomeMetric',
      //   headerName: 'outcomeMetric ',
      //   width: 140,
      //   filter: true,
      //   resizable: true,
      //   cellStyle: {'text-align': 'left'}
      // },
      // {
      //   field: 'outcomeMetricUpperCi',
      //   headerName: 'outcomeUpperCi',
      //   width: 140,
      //   filter: true,
      //   resizable: true,
      //   cellStyle: {'text-align': 'left'}
      // },
      {
        headerName: 'Forest plot (Ratios)',
        field: 'outcomeMetric', // Primary field
        colId: 'forestPlotRatios', // Add colId for Ratios
        cellRenderer: 'd3CellRenderer',
        cellRendererParams: {
          plotType: 'ratios' // Pass your parameter here (e.g., 'ratios' or 'differences')
        },
        cellClass: 'ratios-column',
        headerClass: 'ratios-header no-left-padding no-right-padding', // Custom class for header
        headerComponent: 'd3Header',
        headerComponentParams: {
          globalMinCI: this.globalMinCI,
          globalMaxCI: this.globalMaxCI,
          plotType: 'ratios'
        } as ICustomHeaderParams, // Cast to custom header params
        width: 340,
        suppressHeaderMenuButton: true
      },
      {
        headerName: 'Forest plot (Differences)',
        field: 'outcomeMetric', // Primary field
        colId: 'forestPlotDifferences', // Add colId for Differences
        cellRenderer: 'd3CellRenderer',
        cellRendererParams: {
          plotType: 'differences' // Pass your parameter here (e.g., 'ratios' or 'differences')
        },
        cellClass: 'differences-column',
        headerClass: 'differences-header no-left-padding no-right-padding', // Custom class for header
        headerComponent: 'd3Header',
        headerComponentParams: {
          globalMinCI: this.globalMinCI,
          globalMaxCI: this.globalMaxCI,
          plotType: 'differences'
        } as ICustomHeaderParams, // Cast to custom header params
        width: 340,
        suppressHeaderMenuButton: true
      },
      {
        field: 'metaDataCombined',
        headerName: 'Meta-data',
        width: 240,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        tooltipComponent: 'customTooltip',
        tooltipValueGetter: (params: ValueFormatterParams) => {
          const data = params.data;
          if (!data.outcomeMetric) {
            return 'N/A';
          }
          const parts = [];
          const subgroups = [
            data.outcomeSubgroupVariant
              ? `${data.outcomeSubgroupVariant} (${data.outcomeSubgroupVariantWho || 'N/R'})`
              : '',
            data.outcomeSubgroupComorbidities || '',
            data.outcomeSubgroupVaccinationStatus || '',
            data.outcomeSubgroupAgeYears ? `${data.outcomeSubgroupAgeYears} years old` : '',
            data.outcomeSubgroupOther || ''
          ]
            .filter(Boolean)
            .join(', ');
          if (subgroups.length > 0) {
            parts.push(`${subgroups}`);
          }
          if (data.hospitalizationEndpointEndpoint) {
            parts.push(`, Hospitalization (${data.hospitalizationEndpointEndpoint})`);
          }
          if (data.mortalityEndpoint) {
            parts.push(`, Mortality (${data.mortalityEndpoint})`);
          }
          if (data.compositeEndpoint) {
            if (data.hospitalizationEndpointEndpoint || data.mortalityEndpoint) {
              parts.push(`,`);
            }
            parts.push(`${data.compositeEndpoint};`);
          }
          if (data.compositeOther) {
            parts.push(data.compositeOther);
          }
          return parts.join(' ').trim();
        },
        valueGetter: (params: ValueFormatterParams) => {
          const data = params.data;
          if (!data.outcomeMetric) {
            return 'N/A';
          }
          const parts = [];
          const subgroups = [
            data.outcomeSubgroupVariant
              ? `${data.outcomeSubgroupVariant} (${data.outcomeSubgroupVariantWho || 'N/R'})`
              : '',
            data.outcomeSubgroupComorbidities || '',
            data.outcomeSubgroupVaccinationStatus || '',
            data.outcomeSubgroupAgeYears ? `${data.outcomeSubgroupAgeYears} years old` : '',
            data.outcomeSubgroupOther || ''
          ]
            .filter(Boolean)
            .join(', ');
          if (subgroups.length > 0) {
            parts.push(`${subgroups}`);
          }
          if (data.hospitalizationEndpointEndpoint) {
            parts.push(`, Hospitalization (${data.hospitalizationEndpointEndpoint})`);
          }
          if (data.mortalityEndpoint) {
            parts.push(`, Mortality (${data.mortalityEndpoint})`);
          }
          if (data.compositeEndpoint) {
            if (data.hospitalizationEndpointEndpoint || data.mortalityEndpoint) {
              parts.push(`,`);
            }
            parts.push(`${data.compositeEndpoint};`);
          }
          if (data.compositeOther) {
            parts.push(data.compositeOther);
          }
          return parts.join(' ').trim();
        }
      },
      // {
      //   field: 'outcomeMetric',
      //   headerName: 'Outcome Metric',
      //   width: 180,
      //   filter: true,
      //   resizable: true,
      //   cellStyle: {'text-align': 'left'},
      //   tooltipField: 'outcomeMetric',
      //   tooltipComponent: 'customTooltip'
      // },
      {
        field: 'endpointDescription',
        headerName: 'Other description',
        width: 180,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        tooltipField: 'endpointDescription',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'studyCountry',
        headerName: 'Country',
        width: 190,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        tooltipField: 'studyCountry',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'dataSource',
        headerName: 'DOI',
        width: 100,
        cellRenderer: 'datasourceRenderer'
      }
    ];

    this.gridOptions = {
      headerHeight: 80, // Adjust the header height to fit the axis
      paginationPageSize: 20,
      domLayout: 'normal',
      context: {
        metricColorMap: this.metricColorMap, // Pass metricColorMap via context
        globalMinCI: this.globalMinCI,
        globalMaxCI: this.globalMaxCI
      },
      onGridReady: (params: GridReadyEvent) => {
        this.adjustGridHeight(params); // Correct type: GridReadyEvent
      },
      onPaginationChanged: (params: AgGridEvent) => {
        this.adjustGridHeight(params); // Correct type: PaginationChangedEvent
      }
    };
  }

  public ngOnInit(): void {
    this.activeTab = this.route.snapshot.data['activeTab'];

    const RWEDataPoints$ = this.variantApi.getRWEForestPlot();

    forkJoin([RWEDataPoints$]).subscribe(([RWEDataPoints]) => {
      this.allRWEPoints = RWEDataPoints;
      this.selectedRWEPoints = RWEDataPoints;
      this.rowCount = this.selectedRWEPoints.length;
      this.totalRows = this.allRWEPoints.length;
      // Filter for unique studies and ensure covidenceNb is a string
      this.filteredData = Array.from(
        new Map(RWEDataPoints.map((item) => [String(item['covidenceNb']), item])).values()
      ).map((study) => ({study_country: study.studyCountry, covidenceNb: String(study.covidenceNb)}));
    });
  }

  public adjustGridHeight(params: GridReadyEvent | AgGridEvent): void {
    const gridApi = params.api;

    // Get the current pagination page size
    const pageSize = gridApi.paginationGetPageSize(); // This will give us the actual page size

    // Determine the row height dynamically from the DOM
    let rowHeight = this.gridOptions.rowHeight || 45; // Default to 25px if not set
    const firstRow = document.querySelector('.ag-row');
    if (firstRow) {
      rowHeight = (firstRow as HTMLElement).offsetHeight;
    }

    // Fallback if rowHeight is still undefined or incorrect
    if (!rowHeight) {
      rowHeight = 45; // Hardcoded fallback if DOM retrieval fails
    }

    const newHeight = rowHeight * pageSize + 112; // Adjust for header and padding

    // Find the grid container and apply the new height
    const eGridDiv = document.querySelector('#rweGrid'); // Ensure this matches your grid's id
    if (eGridDiv) {
      (eGridDiv as HTMLElement).style.height = `${newHeight}px`;
    }
  }

  public onGridReady(params: GridReadyEvent): void {
    // if I need to do this I must use gridOptions
    //params.api.setHeaderHeight(80); // Set header height directly
    this.adjustGridHeight(params);
  }

  public filterTable(filters: SelectedRWEFilters) {
    this.selectedRWEPoints = this.RWEFilters.filterPoints(this.allRWEPoints, filters, this.activeTab);
    this.rowCount = this.selectedRWEPoints.length;
  }

  public filterChanged(event: any) {
    this.rowCount = event.api.getDisplayedRowCount();
  }
}

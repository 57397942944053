import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {ReagentDetailsQuery} from '../../models/dtos/trace/reagent-details-ql';

@Injectable({
  providedIn: 'root'
})
export class ReagentDetailsQL extends Query<ReagentDetailsQuery, {}> {
  override document = gql`
    {
      tracereagents {
        id
        sampleId
        reagentName
        reagentCommonName
        reagentClass
        reagentSource
        reagentLot
        reagentConc
        reagentMolecularWeight
        reagentTiter
        reagentAc50Unit
        reagentCas
        reagentSmiles
        reagentLink
        reagentAssays
        reagentMoa
      }
    }
  `;
}

import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {AboutTraceQuery} from '../../models/dtos/trace/about-trace-ql';

@Injectable({
  providedIn: 'root'
})
export class AboutTraceQL extends Query<AboutTraceQuery, {}> {
  override document = gql`
    {
      traceabouts {
        id
        displayOrder
        title
        text
      }
    }
  `;
}

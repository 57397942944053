import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {SmallAnimalModelDetailsResponse} from '../../models/dtos/animal/small-animal';

@Injectable({
  providedIn: 'root'
})
export class SmallAnimalDetailsQL extends Query<SmallAnimalModelDetailsResponse, {}> {
  override document = gql`
    query SmallAnimalModelDetails($modelId: Int!) {
      smallmodelbackgrounds(modelId: $modelId) {
        edges {
          node {
            id
            clinicalSigns
            comorbidities
            diseaseEnhancement
            diseaseManifestationAndPathology
            extentOfDisease
            infectivity
            modelDescription
            modelId
            modelName
            modification
            passage
            routeOfInfection
            species
            status
            transmission
            updated
            vendor
            viralDose
            viralStrain
          }
        }
      }
      smallanimalmodelbgreferences(modelId: $modelId) {
        edges {
          node {
            modelId
            modelName
            species
            primaryReference
            authors
            publisher
            doi
            isPrimary
          }
        }
      }
      smallmodelendpoints(modelId: $modelId) {
        edges {
          node {
            id
            biomarkers
            clinicalLabMeasures
            clinicalObservation
            endpointSummary
            grossPathology
            histopathology
            imaging
            modelId
            modelName
            seroConversion
            updated
            virusReplication
          }
        }
      }
      smallmodelstudies(modelId: $modelId) {
        edges {
          node {
            id
            modelId
            modelName
            agent
            title
            author
            publisher
            doi
            summary
            updated
          }
        }
      }
    }
  `;
}

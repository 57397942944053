import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {AssayOverviewQuery} from '../models/dtos/assay/assay-overview-ql';

@Injectable({
  providedIn: 'root'
})
export class AssayOverviewQL extends Query<AssayOverviewQuery, {}> {
  override document = gql`
    query assayOverviews($assayType: String!) {
      variant3assayoverview(assayType: $assayType) {
        id
        assayType
        viralAgents
        experimentalMethodology
        assayBackground
        assayHowitworks
        assayInterpretation
        assayLimitations
        assayFigureLegend
        assayIcon
        assayFigure
        dateUpdated
      }
    }
  `;
}

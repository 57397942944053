import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {API_URLS, Endpoints} from '@odp/shared';
import {Apollo} from 'apollo-angular';
import {GRAPHQL_ENDPOINT_KEY} from '../../../constants/api-constants';
import {HttpLink} from 'apollo-angular/http';
import {InMemoryCache} from '@apollo/client/core';

import {HomeActivitiessQuery, HomeActivityNode} from '../../../models/dtos/homepage/homepage-ql';
import {HomepageQL} from '../../../queries/homepage-query';
import {HomeActivities} from '../../../models/dtos/homepage/homepage';

@Injectable({
  providedIn: 'root'
})
export class HomepageApiService {
  configService: any;
  constructor(
    httpLink: HttpLink,
    @Inject(API_URLS) private configuration: Endpoints,
    private apollo: Apollo,
    private homepageQL: HomepageQL
  ) {
    const uri = this.configuration.graphqlEndpoint;
    if (!apollo.use(GRAPHQL_ENDPOINT_KEY)) {
      apollo.createNamed(GRAPHQL_ENDPOINT_KEY, {
        link: httpLink.create({uri}),
        cache: new InMemoryCache()
      });
    }
  }

  public getHomepageActivities(): Observable<HomeActivities[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<HomeActivitiessQuery>({query: this.homepageQL.document})
      .pipe(
        map((result: {data: HomeActivitiessQuery}) => {
          return this.mapHeterologousBoosterToEntry(result.data.recentactivities);
        })
      );
  }

  private mapHeterologousBoosterToEntry(heterologousEntry: HomeActivityNode[]): HomeActivities[] {
    const enteries = heterologousEntry.map((e) => {
      const homeActivities: HomeActivities = {
        id: e.id,
        icon: e.icon,
        show: e.show,
        activityType: e.activitytype,
        activityText: e.activitytext,
        date: e.date,
        activityLink: e.activitylink
      };
      return homeActivities;
    });
    return enteries;
  }
}

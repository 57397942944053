import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {API_URLS, Endpoints} from '@odp/shared';
import {Assay} from '../../../models/dtos/assay/assay';
import {AssayOverview} from '../../../models/dtos/assay/assay-overview';
import {AssayOverviewQL} from '../../../queries/assay-overview.query';
import {AssayOverviewsQL} from '../../../queries/assay-overviews.query';
import {AssayQL} from '../../../queries/assay-query';
import {AssayQuery} from '../../../models/dtos/assay/assay-ql';
import {AssayOverviewsQuery} from '../../../models/dtos/assay/assay-overviews-ql';
import {VariantMapperService} from '../../mappers/variant-mapper/variant-mapper.service';
import {Apollo} from 'apollo-angular';
import {GRAPHQL_ENDPOINT_KEY} from '../../../constants/api-constants';
import {HttpLink} from 'apollo-angular/http';
import {InMemoryCache} from '@apollo/client/core';

@Injectable({
  providedIn: 'root'
})
export class AssayApiService {
  private variantBaseUrl!: string;
  configService: any;
  constructor(
    private httpClient: HttpClient,
    httpLink: HttpLink,
    @Inject(API_URLS) private configuration: Endpoints,
    private apollo: Apollo,
    private assayQL: AssayQL,
    private assayOverviewQL: AssayOverviewQL,
    private assayOverviewsQL: AssayOverviewsQL,
    private variantMapper: VariantMapperService
  ) {
    this.variantBaseUrl = configuration.variantApiUrl;

    const uri = this.configuration.graphqlEndpoint;
    if (!apollo.use(GRAPHQL_ENDPOINT_KEY)) {
      apollo.createNamed(GRAPHQL_ENDPOINT_KEY, {
        link: httpLink.create({uri}),
        cache: new InMemoryCache()
      });
    }
  }

  public getAssays(): Observable<Assay[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<AssayQuery>({query: this.assayQL.document})
      .pipe(
        map((result: {data: AssayQuery}) => {
          return result.data.variant3assays;
        })
      );
  }

  public getAssayOverview(assayType: string): Observable<AssayOverview> {
    return this.assayOverviewQL.fetch({assayType: assayType}).pipe(
      map((result) => {
        return result.data.variant3assayoverview;
      })
    );
  }

  public getAllAssayOverviews(): Observable<AssayOverview[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<AssayOverviewsQuery>({query: this.assayOverviewsQL.document})
      .pipe(
        map((result: {data: AssayOverviewsQuery}) => {
          return this.variantMapper.mapToAssayOverviews(result.data.variant3assayoverviews);
        })
      );
  }
}

import {Inject, Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {Lineage} from '../../../models/dtos/lineage/lineage';
import {LineageQL} from '../../../queries/lineage-query';
import {VariantMapperService} from '../../mappers/variant-mapper/variant-mapper.service';
import {API_URLS, Endpoints} from '@odp/shared';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Sublineage} from '../../../models/dtos/in-vitro/sublineage';

@Injectable({
  providedIn: 'root'
})
export class LineageApiService {
  constructor(
    private lineageQL: LineageQL,
    private variantMapper: VariantMapperService,
    @Inject(API_URLS) private configuration: Endpoints,
    private httpClient: HttpClient
  ) {}
  public getLineages(): Observable<Lineage[]> {
    return this.lineageQL.fetch().pipe(map((result) => result.data.viralmetas));
  }
  public getSublineages(): Observable<Sublineage[]> {
    const url = `${this.configuration.variantApiUrl}/route/variant/sublineages`;
    return this.httpClient.get<{data: Sublineage[]}>(url).pipe(
      map((result) => {
        return result.data;
      })
    );
  }
}

<app-header-view></app-header-view>
<app-menu></app-menu>
<div class="container-fluid hero">
  <div class="container py-4">
    <div class="row">
      <div class="col-12 page-title">
        <h2>OpenData Portal | SARS-CoV-2 Variants & Therapeutics</h2>
        <h3 class="subheading">
          Summary
          <span class="btn btn-primary bg-white text-dark border-0 ml-3" *ngIf="hasData || !dataLoading"
            >Updated {{ lastUpdatedTxt }}
          </span>
        </h3>
        <div class="d-flex pb-4">
          <div class="w-60">
            <div class="scores">
              <div>
                <span class="score">{{ dataSourceCount }}</span> data sources
              </div>
              <div class="ml-2">
                <span class="score">{{ recordCount }}</span> activity data points
              </div>
            </div>
          </div>
        </div>
        <p>
          OpenData Portal, in collaboration with ACTIV and industry partners, has compiled a database of in vitro
          therapeutic activity against SARS-CoV-2 variants from a prioritized set of publications (both preprints and
          peer-reviewed articles).
        </p>
        <a
          href="https://opendata.ncats.nih.gov/public/odp/TRACE-INVITRO-DATA.zip"
          class="btn btn-primary bg-white download-all"
          *ngIf="hasData || !dataLoading"
        >
          Download the in vitro datasets here
        </a>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row py-3 dataset mb-5 px-3">
    <div class="col-12 p-0 py-3">
      <p><a [routerLink]="['/covid19']">Home </a> > Variant Therapeutic in vitro Data Summary</p>
    </div>
    <div class="col-12 p-0">
      <div class="row">
        <div class="col-9 page-title">
          <p>
            The table below summarizes all therapeutic and variant pairs that have been collected in this resource.
            <br />
            <b>Click the links below to view in vitro datasets for individual therapeutics against each variant.</b>
            <br />
          </p>
          <button class="btn heatmap-btn" (click)="showHeatmap = !showHeatmap">
            {{ !showHeatmap ? 'Switch to Heatmap View' : 'Switch to Table View' }}
          </button>
        </div>
        <div class="col-3 text-right d-none d-md-block d-lg-block">
          <a href="https://fnih.org/" target="_blank">
            <img width="150" src="./assets/images/fnih.png" alt="FNIH Logo" />
          </a>
        </div>
      </div>
    </div>
    <div class="col-12 p-0" [hidden]="!showHeatmap">
      <div>
        <app-heatmap></app-heatmap>
      </div>
    </div>
    <ng-container *ngIf="!showHeatmap">
      <div class="col-12 p-0 d-none d-md-block d-lg-block">
        <table class="table variant-summary mt-3 mb-0"></table>

        <table *ngIf="hasData; else nodata" class="table variant-summary mb-3">
          <thead>
            <tr class="down-header">
              <th scope="col" sortable="variant">Variant Lineage</th>
              <th scope="col">Vaccines</th>
              <th scope="col">Antivirals</th>
              <th scope="col">Neutralizing Antibodies</th>
              <th scope="col">Convalescent plasma</th>
            </tr>
          </thead>
          <tbody class="inner-table">
            <ng-container *ngFor="let variant of lineageSummaries">
              <!--- Merged Therapeutic Items-->
              <ng-container>
                <tr>
                  <td class="p-3">
                    <div class="v-title">{{ variant.name ? variant.name : 'Unknown' }}</div>
                    <span class="d-block">{{ variant.who }}</span>
                    <span *ngIf="variant.classification === 'VOC'" class="viralClassification pink-border">{{
                      variant.classification
                    }}</span>
                    <span *ngIf="variant.classification === 'VOI'" class="viralClassification golden-border">{{
                      variant.classification
                    }}</span>
                    <span
                      *ngIf="variant.classification === 'VBM'"
                      class="viralClassification yellowgreen-border"
                      style=""
                      >{{ variant.classification }}</span
                    >
                    <span *ngIf="variant.classification === 'Reference'" class="viralClassification">{{
                      variant.classification
                    }}</span>
                    <span *ngIf="variant.classification === 'Other'" class="viralClassification blue-border">{{
                      variant.classification
                    }}</span>
                  </td>
                  <td *ngIf="therapeuticsByLineage.get(variant.name)?.length; else noDataInfo" class="p-3">
                    <div
                      *ngFor="
                        let t of therapeuticsByLineage.get(variant.name) ?? []
                          | sort : 'asc' : 'drugName'
                          | filterByDrugclass : 'Vaccine';
                        let i = index;
                        let length = count;
                        let isLast = last
                      "
                    >
                      <div *ngIf="i < maxItemToShow" class="thera-item">
                        <a [routerLink]="['/datasets']" [queryParams]="{query: t.drugName, and: [variant.name]}">
                          {{ t.drugName }}
                        </a>
                      </div>
                      <div *ngIf="i >= maxItemToShow && showMap?.get(variant.name)?.get('Vaccine')">
                        <a [routerLink]="['/datasets']" [queryParams]="{query: t.drugName, and: [variant.name]}">
                          {{ t.drugName }}
                        </a>
                      </div>
                      <button
                        class="show-thra-toggle"
                        *ngIf="length > maxItemToShow && isLast"
                        (click)="showMap.get(variant.name)?.set('Vaccine', !showMap.get(variant.name)?.get('Vaccine'))"
                      >
                        <div *ngIf="showMap?.get(variant.name)?.get('Vaccine')">Hide</div>
                        <div *ngIf="!showMap?.get(variant.name)?.get('Vaccine')">
                          Show {{ length - maxItemToShow }} more
                        </div>
                      </button>
                    </div>
                  </td>
                  <td *ngIf="therapeuticsByLineage.get(variant.name)?.length; else noDataInfo" class="p-3">
                    <div
                      *ngFor="
                        let t of therapeuticsByLineage.get(variant.name) ?? []
                          | sort : 'asc' : 'drugName'
                          | filterByDrugclass : 'Antiviral';
                        let i = index;
                        let length = count;
                        let isLast = last
                      "
                    >
                      <div *ngIf="i < maxItemToShow" class="thera-item">
                        <a [routerLink]="['/datasets']" [queryParams]="{query: t.drugName, and: [variant.name]}">
                          {{ t.drugName }}
                        </a>
                      </div>
                      <div *ngIf="i >= maxItemToShow && showMap?.get(variant.name)?.get('Antiviral')">
                        <a [routerLink]="['/datasets']" [queryParams]="{query: t.drugName, and: [variant.name]}">
                          {{ t.drugName }}
                        </a>
                      </div>
                      <button
                        class="show-thra-toggle"
                        *ngIf="length > maxItemToShow && isLast"
                        (click)="
                          showMap.get(variant.name)?.set('Antiviral', !showMap.get(variant.name)?.get('Antiviral'))
                        "
                      >
                        <div *ngIf="showMap?.get(variant.name)?.get('Antiviral')">Hide</div>
                        <div *ngIf="!showMap?.get(variant.name)?.get('Antiviral')">
                          Show {{ length - maxItemToShow }} more
                        </div>
                      </button>
                    </div>
                  </td>
                  <td *ngIf="therapeuticsByLineage.get(variant.name)?.length; else noDataInfo" class="p-3">
                    <div
                      *ngFor="
                        let t of therapeuticsByLineage.get(variant.name) ?? []
                          | sort : 'asc' : 'drugName'
                          | filterByDrugclass : 'Neutralizing antibody';
                        let i = index;
                        let length = count;
                        let isLast = last
                      "
                    >
                      <div *ngIf="i < maxItemToShow" class="thera-item">
                        <a [routerLink]="['/datasets']" [queryParams]="{query: t.drugName, and: [variant.name]}">
                          {{ t.drugName }}
                        </a>
                      </div>
                      <div *ngIf="i >= maxItemToShow && showMap?.get(variant.name)?.get('Neutralizing antibody')">
                        <a [routerLink]="['/datasets']" [queryParams]="{query: t.drugName, and: [variant.name]}">
                          {{ t.drugName }}
                        </a>
                      </div>
                      <button
                        class="show-thra-toggle"
                        *ngIf="length > maxItemToShow && isLast"
                        (click)="
                          showMap
                            .get(variant.name)
                            ?.set('Neutralizing antibody', !showMap.get(variant.name)?.get('Neutralizing antibody'))
                        "
                      >
                        <div *ngIf="showMap?.get(variant.name)?.get('Neutralizing antibody')">Hide</div>
                        <div *ngIf="!showMap?.get(variant.name)?.get('Neutralizing antibody')">
                          Show {{ length - maxItemToShow }} more
                        </div>
                      </button>
                    </div>
                  </td>
                  <td *ngIf="therapeuticsByLineage.get(variant.name)?.length; else noDataInfo" class="p-3">
                    <div
                      *ngFor="
                        let t of therapeuticsByLineage.get(variant.name) ?? []
                          | sort : 'asc' : 'drugName'
                          | filterByDrugclass : 'Convalescent plasma';
                        let i = index;
                        let length = count;
                        let isLast = last
                      "
                    >
                      <div *ngIf="i < maxItemToShow" class="thera-item">
                        <a [routerLink]="['/datasets']" [queryParams]="{query: t.drugName, and: [variant.name]}">
                          {{ t.drugName }}
                        </a>
                      </div>
                      <div *ngIf="i >= maxItemToShow && showMap?.get(variant.name)?.get('Convalescent plasma')">
                        <a [routerLink]="['/datasets']" [queryParams]="{query: t.drugName, and: [variant.name]}">
                          {{ t.drugName }}
                        </a>
                      </div>
                      <button
                        class="show-thra-toggle"
                        *ngIf="length > maxItemToShow && isLast"
                        (click)="
                          showMap
                            .get(variant.name)
                            ?.set('Convalescent plasma', !showMap.get(variant.name)?.get('Convalescent plasma'))
                        "
                      >
                        <div *ngIf="showMap?.get(variant.name)?.get('Convalescent plasma')">Hide</div>
                        <div *ngIf="!showMap?.get(variant.name)?.get('Convalescent plasma')">
                          Show {{ length - maxItemToShow }} more
                        </div>
                      </button>
                    </div>
                  </td>
                  <ng-template #noDataInfo>
                    <td class="p-3" style="border-bottom: 1px solid #000">
                      <small class="text-muted"></small>
                    </td>
                  </ng-template>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>

        <div class="col-12 my-4 d-flex justify-content-center">
          <ng-template #nodata>
            <div *ngIf="dataLoading">
              <app-loader></app-loader>
            </div>
            <div *ngIf="!dataLoading">
              <ngb-alert>No Variant Found.</ngb-alert>
            </div>
          </ng-template>
        </div>
      </div>

      <!-- Mobile View -->
      <div class="col-12 p-0 d-sm-block d-md-none d-lg-none mobile-container">
        <h4 class="d-sm-block d-md-none d-lg-none sub-info mt-3">Preclinical Data Available</h4>
        <div class="lineage-info my-3">
          <h5>Variant Lineage</h5>
          <div class="row">
            <div class="col-1">
              <span class="viraltype-icon">
                <img src="./assets/svg/variant-lineage_live-virus_icn.svg" alt="Live Virus Icon" />
              </span>
            </div>
            <div class="col-11">Live Virus</div>
            <div class="col-1 mt-1">
              <span class="viraltype-icon">
                <img src="./assets/svg/variant-lineage_pseudovirus_icn.svg" alt="Pseudovirus Icon" />
              </span>
            </div>
            <div class="col-11 mt-1">Pseudovirus</div>
          </div>
        </div>
        <table *ngIf="orderedMobileData; else nodata" class="table variant-summary my-3">
          <tbody>
            <ng-container *ngFor="let mobileVariant of orderedMobileData | keyvalue">
              <tr
                *ngIf="mobileVariant.key"
                style="background: #3e626b; color: #fff; border-bottom: 1px solid #dedede; cursor: pointer"
                (click)="showVariantMap.set(mobileVariant.key, !showVariantMap.get(mobileVariant.key))"
              >
                <td colspan="5" class="v-title px-3">
                  {{ mobileVariant.key ? mobileVariant.key : 'Unknown' }}
                  <span *ngIf="!showVariantMap.get(mobileVariant.key)" class="ml-3 expand-arrow text-white float-right"
                    ><i class="fas fa-chevron-down"></i
                  ></span>
                  <span *ngIf="showVariantMap.get(mobileVariant.key)" class="ml-3 expand-arrow text-white float-right"
                    ><i class="fas fa-chevron-up"></i
                  ></span>
                </td>
              </tr>

              <ng-container *ngIf="showVariantMap.get(mobileVariant.key)">
                <div class="inner-table px-3">
                  <table class="w-100">
                    <ng-container *ngFor="let drugClass of mobileVariant.value | keyvalue">
                      <tr
                        *ngIf="drugClass.key"
                        class="py-2"
                        style="border-bottom: 1px solid #dedede; cursor: pointer"
                        (click)="
                          showClassMap
                            .get(mobileVariant.key)!
                            .set(drugClass.key, !showClassMap.get(mobileVariant.key)!.get(drugClass.key))
                        "
                      >
                        <div class="py-3 drugclass">
                          {{ drugClass.key }}
                          <span
                            *ngIf="!showClassMap.get(mobileVariant.key)!.get(drugClass.key)"
                            class="expand-arrow float-right"
                            ><i class="fas fa-chevron-down"></i
                          ></span>
                          <span
                            *ngIf="showClassMap.get(mobileVariant.key)!.get(drugClass.key)"
                            class="expand-arrow float-right"
                            ><i class="fas fa-chevron-up"></i
                          ></span>
                        </div>
                      </tr>
                      <ng-container *ngIf="showClassMap.get(mobileVariant.key)!.get(drugClass.key)">
                        <ng-container *ngFor="let thera of drugClass.value | keyvalue">
                          <tr *ngIf="thera.key" class="py-2" style="border-bottom: 1px solid #dedede">
                            <div class="py-3 thera">
                              <div class="row">
                                <div class="col-10">
                                  <a
                                    [routerLink]="['/datasets']"
                                    [queryParams]="{query: thera.key, and: [mobileVariant.key]}"
                                    >{{ thera.key }}</a
                                  >
                                </div>
                                <div class="col-1 px-0 text-center">
                                  <div *ngFor="let type of thera.value">
                                    <img
                                      width="24"
                                      *ngIf="type.viralType === 'Live virus'"
                                      src="./assets/svg/variant-lineage_live-virus_icn.svg"
                                      alt="Live Virus Icon"
                                    />
                                  </div>
                                </div>
                                <div class="col-1 px-0">
                                  <div *ngFor="let type of thera.value">
                                    <img
                                      width="24"
                                      *ngIf="type.viralType === 'Pseudovirus'"
                                      src="./assets/svg/variant-lineage_pseudovirus_icn.svg"
                                      alt="Pseudovirus Icon"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </tr>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </table>
                </div>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </ng-container>
  </div>
</div>
<app-footer-view [showCC]="true" *ngIf="hasData || !dataLoading"></app-footer-view>

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SmallAnimalDetailsQL} from '../../../queries/animal/small-animal-details-query';
import {AnimalModelsQL} from '../../../queries/animal/animal-model-query';
import {NonHumanPrimateDetailsQL} from '../../../queries/animal/non-human-primate-details-query';
import {
  AnimalModelResponse,
  AnimalModels,
  NonHumanPrimateModelDetails,
  SmallAnimal,
  SmallAnimalModelDetails,
  SmallAnimalModelDetailsResponse,
  NonHumanPrimateModelDetailsResponse
} from '../../../models/dtos/animal';
import {HttpLink} from 'apollo-angular/http';
import {InMemoryCache} from '@apollo/client/core';
import {API_URLS, Endpoints} from '@odp/shared';
import {Apollo} from 'apollo-angular';
import {TherapeuticGlossaryQL} from '../../../queries/therapeutic-glossary';
import {GRAPHQL_ENDPOINT_KEY} from '../../../constants/api-constants';
import {IntValueNode} from 'graphql';

@Injectable({
  providedIn: 'root'
})
export class AnimalModelServiceNew {
  private variantBaseUrl!: string;
  configService: any;
  constructor(
    private httpClient: HttpClient,
    httpLink: HttpLink,
    @Inject(API_URLS) private configuration: Endpoints,
    private apollo: Apollo,
    private smallAnimalQL: AnimalModelsQL,
    private smallAnimalDetailsQL: SmallAnimalDetailsQL,
    private nonHumanPrimateDetailsQL: NonHumanPrimateDetailsQL
  ) {
    this.variantBaseUrl = configuration.covid19ApiUrl;

    const uri = this.configuration.graphqlEndpoint;
    if (!apollo.use(GRAPHQL_ENDPOINT_KEY)) {
      apollo.createNamed(GRAPHQL_ENDPOINT_KEY, {
        link: httpLink.create({uri}),
        cache: new InMemoryCache()
      });
    }
  }

  public getAnimalModel(): Observable<AnimalModels> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<AnimalModelResponse>({query: this.smallAnimalQL.document})
      .pipe(
        map((response) => {
          return {
            smallAnimals: response.data.smallanimalmodels
              .map((e: any) => e)
              .sort(function (a, b) {
                if (a.species === b.species) {
                  return a.modification < b.modification ? -1 : 1;
                } else {
                  return a.species < b.species ? -1 : 1;
                }
              }),
            nonHumanPrimates: response.data.nonhumanprimatemodels
              .map((e: any) => e)
              .sort(function (a, b) {
                return a.species < b.species ? -1 : 1;
              })
          };
        })
      );
  }

  public getSmallAnimaModelDetails(modelId: Number): Observable<SmallAnimalModelDetails> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<SmallAnimalModelDetailsResponse>({
        query: this.smallAnimalDetailsQL.document,
        variables: {modelId: modelId}
      })
      .pipe(
        map((response) => {
          return {
            background: response.data.smallmodelbackgrounds.edges[0]?.node,
            primaryReferences: response.data.smallanimalmodelbgreferences.edges
              .map((e: any) => e.node)
              .filter((e: any) => e.isPrimary),
            additionalReferences: response.data.smallanimalmodelbgreferences.edges
              .map((e: any) => e.node)
              .filter((e: any) => !e.isPrimary),
            endpoint: response.data.smallmodelendpoints.edges[0]?.node,
            studies: response.data.smallmodelstudies.edges?.map((e: any) => e.node)
          };
        })
      );
  }

  public getNonHumanPrimateModelDetails(modelId: Number): Observable<NonHumanPrimateModelDetails> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<NonHumanPrimateModelDetailsResponse>({
        query: this.nonHumanPrimateDetailsQL.document,
        variables: {modelId: modelId}
      })
      .pipe(
        map((response) => {
          return {
            background: response.data.nonhumanprimatebackgrounds.edges[0]?.node,
            primaryReferences: response.data.nonhumanprimatemodelbgreferences.edges
              .map((e: any) => e.node)
              .filter((e: any) => e.isPrimary),
            additionalReferences: response.data.nonhumanprimatemodelbgreferences.edges
              .map((e: any) => e.node)
              .filter((e: any) => !e.isPrimary),
            endpoint: response.data.nonhumanprimatemodelendpoints.edges[0]?.node
          };
        })
      );
  }
}

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {map, Observable, throwError, catchError} from 'rxjs';
import {RWEForestPlotPoint} from '../../../models/dtos/rweforestplot/rweforestplot';
import {API_URLS, Endpoints} from '@odp/shared';
import {ApolloQueryResult, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {Apollo} from 'apollo-angular';
import {GRAPHQL_ENDPOINT_KEY} from '../../../constants/api-constants';
import {RWEForestPlotNode, RWEForestPlotQuery} from '../../../models/dtos/rweforestplot/rweforestplot-ql';
import {RWEForestPlotQL} from '../../../queries/rweforestplot-query';

@Injectable({
  providedIn: 'root'
})
export class RWEForestPlotApiService {
  private variantBaseUrl!: string;

  constructor(
    private httpClient: HttpClient,
    httpLink: HttpLink,
    @Inject(API_URLS) private configuration: Endpoints,
    private apollo: Apollo,
    private rweforestplotQL: RWEForestPlotQL
  ) {
    // URL pointing to your FastAPI GraphQL endpoint
    this.variantBaseUrl = configuration.graphqlEndpoint;

    const uri = this.configuration.graphqlEndpoint;
    if (!apollo.use(GRAPHQL_ENDPOINT_KEY)) {
      apollo.createNamed(GRAPHQL_ENDPOINT_KEY, {
        link: httpLink.create({uri}),
        cache: new InMemoryCache()
      });
    }
  }

  // Apollo GraphQL Query Example
  public getRWEForestPlot(): Observable<RWEForestPlotPoint[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<RWEForestPlotQuery>({query: this.rweforestplotQL.document})
      .pipe(
        map((result: ApolloQueryResult<RWEForestPlotQuery>) => {
          if (!!result && !!result.errors?.length) {
            throw new Error('GraphQL errors: ' + result.errors.map((e) => e.message).join(', '));
          }
          // No longer mapping through 'edges', directly map the returned list of records
          return this.mapRWEForestPlotToEntry(result.data.getAllRealworldevidenceforestplots);
        }),
        catchError((error) => {
          console.error('Error fetching RWE Forest Plot data:', error);
          return throwError(() => new Error('Error fetching RWE Forest Plot data'));
        })
      );
  }

  // Mapping function from GraphQL query
  public mapRWEForestPlotToEntry(rweEntries: RWEForestPlotNode[]): RWEForestPlotPoint[] {
    if (!rweEntries) {
      console.error('No data provided to mapRWEForestPlotToEntry');
      return []; // Return empty array or handle the error appropriately
    }
    const entries = rweEntries.map((e) => {
      const rwe: RWEForestPlotPoint = {
        id: e.id,
        reviewer: e.reviewer,
        covidenceNb: e.covidenceNb,
        dataTitle: e.dataTitle,
        firstAuthor: e.firstAuthor,
        publicationDate: e.publicationDate,
        dataSourceType: e.dataSourceType,
        studyCountry: e.studyCountry,
        studyStartDate: e.studyStartDate,
        studyEndDate: e.studyEndDate,
        randomizedControlledTrial: e.randomizedControlledTrial,
        startingCohortHospitalized: e.startingCohortHospitalized,
        drug1: e.drug1,
        drug1Id: e.drug1Id,
        drug1Class: e.drug1Class,
        drug1N: e.drug1N,
        drug1Time: e.drug1Time,
        drug2: e.drug2,
        drug2Id: e.drug2Id,
        drug2Class: e.drug2Class,
        drug2N: e.drug2N,
        drug2Time: e.drug2Time,
        controlOrComparator: e.controlOrComparator,
        comparatorName: e.comparatorName,
        controlN: e.controlN,
        totalPopulationN: e.totalPopulationN,
        endpoint: e.endpoint,
        endpointPriority: e.endpointPriority,
        endpointComposite: e.endpointComposite,
        otherEndpointComposite: e.otherEndpointComposite,
        endpointDescription: e.endpointDescription,
        outcomeReadoutType: e.outcomeReadoutType,
        outcomeDrugN: e.outcomeDrugN,
        outcomeDrugReadout: e.outcomeDrugReadout,
        outcomeControlOrComparatorN: e.outcomeControlOrComparatorN,
        outcomeControlOrComparatorReadout: e.outcomeControlOrComparatorReadout,
        outcomeSubgroupVariant: e.outcomeSubgroupVariant,
        outcomeSubgroupVariantWho: e.outcomeSubgroupVariantWho,
        outcomeSubgroupComorbidities: e.outcomeSubgroupComorbidities,
        outcomeSubgroupVaccinationStatus: e.outcomeSubgroupVaccinationStatus,
        outcomeSubgroupAgeYears: e.outcomeSubgroupAgeYears,
        outcomeSubgroupOther: e.outcomeSubgroupOther,
        compositeEndpoint: e.compositeEndpoint,
        hospitalizationEndpoint: e.hospitalizationEndpoint,
        mortalityEndpoint: e.mortalityEndpoint,
        compositeOther: e.compositeOther,
        metricNuance: e.metricNuance,
        outcomeMetricType: e.outcomeMetricType,
        graphPosition: e.graphPosition,
        outcomeMetric: e.outcomeMetric,
        outcomeMetricLowerCi: e.outcomeMetricLowerCi,
        outcomeMetricUpperCi: e.outcomeMetricUpperCi,
        outcomeMetricLowerCiCalc: e.outcomeMetricLowerCiCalc,
        outcomeMetricUpperCiCalc: e.outcomeMetricUpperCiCalc,
        outcomeMetricPValue: e.outcomeMetricPValue,
        outcomeMetricTimepointDays: e.outcomeMetricTimepointDays,
        hospitalizationTimepointDays: e.hospitalizationTimepointDays,
        mortalityTimepointDays: e.mortalityTimepointDays,
        pmid: e.pmid,
        pmcid: e.pmcid,
        dataSource: e.dataSource,
        dosage: e.dosage,
        abbreviatedSummary: e.abbreviatedSummary,
        inclusionCriteriaMethods: e.inclusionCriteriaMethods,
        exclusionCriteriaMethods: e.exclusionCriteriaMethods,
        methodsStatsAnalysis: e.methodsStatsAnalysis,
        platform: e.platform,
        notes: e.notes,
        studyLocation: e.studyLocation,
        cohortUniqueness: e.cohortUniqueness
      };
      return rwe;
    });
    return entries;
  }
}

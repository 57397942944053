import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {HomeActivitiessQuery} from '../models/dtos/homepage/homepage-ql';

@Injectable({
  providedIn: 'root'
})
export class HomepageQL extends Query<HomeActivitiessQuery, {}> {
  override document = gql`
    {
      recentactivities {
        id
        icon
        activitytype
        activitytext
        date
        activitylink
        show
      }
    }
  `;
}

import {Component, OnInit, Input} from '@angular/core';
import {ValueFormatterParams, ITooltipParams} from 'ag-grid-community';
import {forkJoin} from 'rxjs';
import {DatasourceRendererComponent} from '../datasource-renderer/datasource-renderer.component';
import {SelectedRWEFilters} from '../rwe-forestplot-filters/rwe-forestplot-filters.component';
import {RWEForestPlotReportTooltipComponent} from '../rwe-forestplot-report-tooltip/rwe-forestplot-report-tooltip.component';
import {RWEForestPlotFilterService} from '../rwe-forestplot-filter/rwe-forestplot-filter.service';
import {RWEForestPlotPoint} from '../../../models/dtos/rweforestplot/rweforestplot';
import {RWEForestPlotApiService} from '../../../services/api/rweforestplot-api/rweforestplot-api.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-rwe-forestplot-data',
  templateUrl: './rwe-forestplot-data.component.html',
  styleUrls: ['./rwe-forestplot-data.component.scss']
})
export class RWEForestPlotDataComponent implements OnInit {
  public activeTab!: 'tabular' | 'forestplot';
  public allRWEPoints!: RWEForestPlotPoint[];
  public selectedRWEPoints!: RWEForestPlotPoint[];
  public frameworkComponents: any;
  public defaultColDef: any;
  public columnDefs: any;
  public rowCount!: number;
  public totalRows!: number;

  constructor(
    private variantApi: RWEForestPlotApiService,
    private RWEFilters: RWEForestPlotFilterService,
    private route: ActivatedRoute
  ) {
    this.frameworkComponents = {
      customTooltip: RWEForestPlotReportTooltipComponent,
      datasourceRenderer: DatasourceRendererComponent
    };

    this.defaultColDef = {
      width: 200,
      filter: true,
      sortable: true,
      resizable: true,
      cellStyle: {'border-bottom': '1px solid #ddd'}
    };

    this.columnDefs = [
      {
        field: 'dataTitle',
        headerName: 'Title',
        width: 300,
        sortable: true,
        resizable: true,
        tooltipField: 'dataTitle',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'publicationDate',
        headerName: 'Publication Date',
        width: 120,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        valueFormatter: (params: ValueFormatterParams) => {
          return params.value
            ? new Date(params.value).toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
              })
            : '';
        }
      },
      {
        field: 'treatmentsCombined',
        headerName: 'Treatment (n)',
        width: 260,
        cellStyle: {'text-align': 'left'},
        tooltipField: 'treatmentsCompiledEdited',
        tooltipComponent: 'customTooltip',
        valueGetter: (params: ValueFormatterParams) => {
          // Initialize an array to collect parts of the display string.
          const treatments = [];

          // Always include drug1 if it exists.
          if (params.data.drug1 && params.data.drug1N) {
            treatments.push(`${params.data.drug1} (${params.data.drug1N})`);
          }

          // Conditionally include drug2 if it exists.
          if (params.data.drug2 && params.data.drug2N) {
            treatments.push(`${params.data.drug2} (${params.data.drug2N})`);
          }

          // Always include 'Control' with its number, assuming controlN exists.
          if (params.data.controlN) {
            treatments.push(`Control (${params.data.controlN})`);
          } else {
            // If controlN is missing, still include 'Control' but indicate no data.
            treatments.push(`Control (N/R)`);
          }

          // Join all parts with a semicolon and space, or return 'N/R' if the array is somehow empty.
          return treatments.length > 0 ? treatments.join('; ') : 'N/R';
        }
      },
      {
        field: 'studyStartDate',
        headerName: 'Study Start',
        width: 100,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'}
      },
      {
        field: 'studyEndDate',
        headerName: 'Study End',
        width: 100,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'}
      },
      {
        field: 'abbreviatedSummary',
        headerName: 'Summary',
        width: 120,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        tooltipField: 'abbreviatedSummary',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'outcomeSubgroupVariantCombined',
        headerName: 'Lineage (WHO name)',
        tooltipField: 'outcomeSubgroupVariantCombined',
        tooltipComponent: 'customTooltip',
        tooltipValueGetter: () => 'Static test tooltip content',
        valueGetter: (params: ValueFormatterParams) => {
          // Combine the two fields with some formatting
          const variant = params.data.outcomeSubgroupVariant || 'N/R'; // Handle null, undefined, or empty string
          const variantWho = params.data.outcomeSubgroupVariantWho || 'N/R'; // Handle null, undefined, or empty string
          return `${variant} (${variantWho})`;
        }
      },
      {
        field: 'endpoint',
        headerName: 'Endpoint',
        width: 150,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'}
      },
      {
        field: 'outcomeMetricType',
        headerName: 'Hospitalization Metric',
        width: 180,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        tooltipField: 'hospitalizationMetricUsed',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'hospitalizationEndptMetricPvalue',
        headerName: 'Hospitalization Outcome (value (95% CI); p-value)',
        width: 300,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        tooltipValueGetter: (params: ITooltipParams) => {
          const data = params.data;

          // Use covidenceNb (since that's what the data object contains)
          const covidenceNb = data?.covidenceNb;

          if (!covidenceNb) {
            console.log('No valid covidenceNb found, returning N/A');
            return 'N/A';
          }

          // Fetch all rows using allRWEPoints, which contains the entire dataset
          const allRows = params.context.componentParent.allRWEPoints;

          // Find related rows with the same covidenceNb
          const relatedRows = allRows.filter((row: any) => row?.covidenceNb === covidenceNb);

          if (relatedRows.length === 0) {
            console.log('No related rows found, returning N/A');
            return 'N/A';
          }

          let tooltipContent = ''; // Initialize an empty string for the tooltip content

          // Loop through the related rows and build the tooltip text
          relatedRows.forEach((row: any) => {
            const parts: string[] = [];

            // Timepoints with conditional content
            const timepoints = [];
            if (row.outcomeMetricTimepointDays) {
              timepoints.push(`${row.outcomeMetricTimepointDays} days`);
            }
            if (row.hospitalizationTimepointDays) {
              timepoints.push(`${row.hospitalizationTimepointDays} days`);
            }

            // Subgroup details
            const subgroups = [
              row.outcomeSubgroupVariant
                ? `${row.outcomeSubgroupVariant} (${row.outcomeSubgroupVariantWho || 'N/R'})`
                : '',
              row.outcomeSubgroupComorbidities || '',
              row.outcomeSubgroupVaccinationStatus || '',
              row.outcomeSubgroupAgeYears ? `${row.outcomeSubgroupAgeYears} years old` : '',
              row.outcomeSubgroupOther || ''
            ]
              .filter(Boolean)
              .join(', ');

            // Add timepoints and subgroups to parts
            if (timepoints.length > 0 && subgroups.length > 0) {
              parts.push(`${timepoints.join(', ')}${subgroups.length > 0 ? ', ' : ''}${subgroups}:`);
            } else if (timepoints.length > 0) {
              parts.push(`${timepoints.join(', ')}:`);
            } else if (subgroups.length > 0) {
              parts.push(`${subgroups}:`);
            }

            // Outcome metric and confidence intervals
            const outcomeMetricDetails =
              row.outcomeMetricLowerCi !== undefined && row.outcomeMetricUpperCi !== undefined
                ? `${row.outcomeMetric} (${row.outcomeMetricLowerCi} - ${row.outcomeMetricUpperCi})`
                : row.outcomeMetric; // Use just the outcome metric if CIs are not available

            parts.push(`${outcomeMetricDetails}; ${row.outcomeMetricPValue || 'N/R'}`);

            // Add special endpoints directly
            let endpoints = '';
            if (row.compositeEndpoint) {
              endpoints += `, ${row.compositeEndpoint}`;
            }
            if (row.hospitalizationEndpoint) {
              endpoints += `, ${row.hospitalizationEndpoint}`;
            }

            if (endpoints) {
              parts[parts.length - 1] += endpoints; // Append to the last part to avoid a separate line
            }

            // Join the parts for this row and add a newline to separate rows
            tooltipContent += `${parts.join(' ')}`;
            tooltipContent += '\n';
          });

          return tooltipContent.trim(); // Return the final tooltip text, trimming any excess new lines
        },
        valueGetter: (params: ValueFormatterParams) => {
          const data = params.data;

          // Return empty if there is no outcome metric, as there's nothing to display
          if (!data.outcomeMetric) {
            return 'N/A';
          }

          const parts = [];

          // Timepoints with conditional content
          const timepoints = [];
          if (data.outcomeMetricTimepointDays) {
            timepoints.push(`${data.outcomeMetricTimepointDays} days`);
          }
          if (data.hospitalizationTimepointDays) {
            timepoints.push(`${data.hospitalizationTimepointDays} days`);
          }

          // Subgroup details with conditional display
          const subgroups = [
            data.outcomeSubgroupVariant
              ? `${data.outcomeSubgroupVariant} (${data.outcomeSubgroupVariantWho || 'N/R'})`
              : '',
            data.outcomeSubgroupComorbidities || '',
            data.outcomeSubgroupVaccinationStatus || '',
            data.outcomeSubgroupAgeYears ? `${data.outcomeSubgroupAgeYears} years old` : '', // Append " years old" if age is present
            data.outcomeSubgroupOther || ''
          ]
            .filter(Boolean)
            .join(', '); // Filters out empty strings

          // If both timepoints and subgroups exist, join them with a comma and add to parts
          if (timepoints.length > 0 && subgroups.length > 0) {
            parts.push(`${timepoints.join(', ')}${subgroups.length > 0 ? ', ' : ''}${subgroups}:`);
          } else if (timepoints.length > 0) {
            parts.push(`${timepoints.join(', ')}:`);
          } else if (subgroups.length > 0) {
            parts.push(`${subgroups}:`);
          }

          // Combine outcome metric with formatted confidence intervals
          const outcomeMetricDetails =
            data.outcomeMetricLowerCi !== undefined && data.outcomeMetricUpperCi !== undefined
              ? `${data.outcomeMetric} (${data.outcomeMetricLowerCi} - ${data.outcomeMetricUpperCi})`
              : data.outcomeMetric; // Use just the outcome metric if CIs are not available

          // Append the outcome metric details and p-value
          parts.push(`${outcomeMetricDetails}; ${data.outcomeMetricPValue || 'N/R'}`);

          // Append special endpoints with symbols if applicable, only adding if they are present
          if (data.compositeEndpoint) {
            parts.push(`, ${data.compositeEndpoint}`);
          }
          if (data.hospitalizationEndpoint) {
            parts.push(`, ${data.hospitalizationEndpoint}`);
          }

          return parts.join(' ').trim(); // Join all parts into one string and trim any excess whitespace
        }
      },
      {
        field: 'outcomeMetricType',
        headerName: 'Mortality Metric',
        width: 180,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        tooltipField: 'mortalityMetricUsed',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'mortalityEndptMetricPvalue',
        headerName: 'Mortality Outcome (value (95% CI); p-value)',
        width: 300,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        //tooltipComponent: 'customTooltip', // REMOVE THIS IF USING INLINE tooltipValueGetter
        tooltipValueGetter: (params: ITooltipParams) => {
          const data = params.data;

          // Use covidenceNb (since that's what the data object contains)
          const covidenceNb = data?.covidenceNb;

          if (!covidenceNb) {
            console.log('No valid covidenceNb found, returning N/A');
            return 'N/A';
          }

          // Fetch all rows using allRWEPoints, which contains the entire dataset
          const allRows = params.context?.componentParent?.allRWEPoints || [];

          // Find related rows with the same covidenceNb
          const relatedRows = allRows.filter((row: any) => row?.covidenceNb === covidenceNb);

          if (relatedRows.length === 0) {
            console.log('No related rows found, returning N/A');
            return 'N/A';
          }

          let tooltipContent = ''; // Initialize an empty string for the tooltip content

          // Loop through the related rows and build the tooltip text
          relatedRows.forEach((row: any) => {
            const parts: string[] = [];

            // Timepoints with conditional content
            const timepoints = [];
            if (row.outcomeMetricTimepointDays) {
              timepoints.push(`${row.outcomeMetricTimepointDays} days`);
            }
            if (row.mortalityTimepointDays) {
              timepoints.push(`${row.mortalityTimepointDays} days`);
            }

            // Subgroup details
            const subgroups = [
              row.outcomeSubgroupVariant
                ? `${row.outcomeSubgroupVariant} (${row.outcomeSubgroupVariantWho || 'N/R'})`
                : '',
              row.outcomeSubgroupComorbidities || '',
              row.outcomeSubgroupVaccinationStatus || '',
              row.outcomeSubgroupAgeYears ? `${row.outcomeSubgroupAgeYears} years old` : '',
              row.outcomeSubgroupOther || ''
            ]
              .filter(Boolean)
              .join(', ');

            // Add timepoints and subgroups to parts
            if (timepoints.length > 0 && subgroups.length > 0) {
              parts.push(`${timepoints.join(', ')}${subgroups.length > 0 ? ', ' : ''}${subgroups}:`);
            } else if (timepoints.length > 0) {
              parts.push(`${timepoints.join(', ')}:`);
            } else if (subgroups.length > 0) {
              parts.push(`${subgroups}:`);
            }

            // Outcome metric and confidence intervals
            const outcomeMetricDetails =
              row.outcomeMetricLowerCi !== undefined && row.outcomeMetricUpperCi !== undefined
                ? `${row.outcomeMetric} (${row.outcomeMetricLowerCi} - ${row.outcomeMetricUpperCi})`
                : row.outcomeMetric; // Use just the outcome metric if CIs are not available

            parts.push(`${outcomeMetricDetails}; ${row.outcomeMetricPValue || 'N/R'}`);

            // Add special endpoints directly
            let endpoints = '';
            if (row.compositeEndpoint) {
              endpoints += `, ${row.compositeEndpoint}`;
            }
            if (row.hospitalizationEndpoint) {
              endpoints += `, ${row.hospitalizationEndpoint}`;
            }

            if (endpoints) {
              parts[parts.length - 1] += endpoints; // Append to the last part to avoid a separate line
            }

            // Join the parts for this row, and trim each part to ensure no leading/trailing spaces
            tooltipContent += `${parts.join(' ').trim()}\n`; // Add a new line between rows
          });

          // Apply both trimming and strip excess whitespace
          return tooltipContent.replace(/^\s+/gm, '').trim(); // Remove any leading whitespace on each line
        },
        valueGetter: (params: ValueFormatterParams) => {
          const data = params.data;

          // Return empty if there is no outcome metric, as there's nothing to display
          if (!data.outcomeMetric) {
            return 'N/A';
          }

          const parts = [];

          // Timepoints with conditional content
          const timepoints = [];
          if (data.outcomeMetricTimepointDays) {
            timepoints.push(`${data.outcomeMetricTimepointDays} days`);
          }
          if (data.mortalityTimepointDays) {
            timepoints.push(`${data.mortalityTimepointDays} days`);
          }

          // Subgroup details with conditional display
          const subgroups = [
            data.outcomeSubgroupVariant
              ? `${data.outcomeSubgroupVariant} (${data.outcomeSubgroupVariantWho || 'N/R'})`
              : '',
            data.outcomeSubgroupComorbidities || '',
            data.outcomeSubgroupVaccinationStatus || '',
            data.outcomeSubgroupAgeYears ? `${data.outcomeSubgroupAgeYears} years old` : '', // Append " years old" if age is present
            data.outcomeSubgroupOther || ''
          ]
            .filter(Boolean)
            .join(', '); // Filters out empty strings

          // If both timepoints and subgroups exist, join them with a comma and add to parts
          if (timepoints.length > 0 && subgroups.length > 0) {
            parts.push(`${timepoints.join(', ')}${subgroups.length > 0 ? ', ' : ''}${subgroups}:`);
          } else if (timepoints.length > 0) {
            parts.push(`${timepoints.join(', ')}:`);
          } else if (subgroups.length > 0) {
            parts.push(`${subgroups}:`);
          }

          // Combine outcome metric with formatted confidence intervals
          const outcomeMetricDetails =
            data.outcomeMetricLowerCi !== undefined && data.outcomeMetricUpperCi !== undefined
              ? `${data.outcomeMetric} (${data.outcomeMetricLowerCi} - ${data.outcomeMetricUpperCi})`
              : data.outcomeMetric; // Use just the outcome metric if CIs are not available

          // Append the outcome metric details and p-value
          parts.push(`${outcomeMetricDetails}; ${data.outcomeMetricPValue || 'N/R'}`);

          // Append special endpoints with symbols if applicable, adding a comma if there is already preceding text
          if (data.compositeEndpoint) {
            parts.push(`, ${data.compositeEndpoint}`);
          }

          if (data.hospitalizationEndpoint) {
            parts.push(`, ${data.hospitalizationEndpoint}`);
          }

          return parts.join(' ').trim(); // Join all parts into one string and trim any excess whitespace
        }
      },
      {
        field: 'endpointDescription',
        headerName: 'Other Defined',
        width: 180,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        tooltipField: 'otherDefined',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'outcomeMetricType',
        headerName: 'Other Metric',
        width: 180,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        tooltipField: 'outcomeMetricTypeOther',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'otherEndpointMetricPvalue',
        headerName: 'Other Outcome (value (95% CI); p-value)',
        width: 300,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        tooltipValueGetter: (params: ValueFormatterParams) => {
          const data = params.data;

          // Access allRWEPoints directly from the parent component's context (the full dataset)
          const allRows = params.context?.componentParent?.allRWEPoints || [];

          // Use covidenceNb to find related rows
          const covidenceNb = data?.covidenceNb;
          if (!covidenceNb) {
            return 'N/A';
          }

          // Find related rows with the same covidenceNb
          const relatedRows = allRows.filter((row: any) => row?.covidenceNb === covidenceNb);

          if (relatedRows.length === 0) {
            return 'N/A';
          }

          let tooltipContent = ''; // Initialize an empty string for the tooltip content

          // Loop through related rows to build tooltip
          relatedRows.forEach((row: any, index: number) => {
            const parts: string[] = [];

            // Timepoints with conditional content
            const timepoints = [];
            if (row.outcomeMetricTimepointDays) {
              timepoints.push(`${row.outcomeMetricTimepointDays} days`);
            }
            if (row.hospitalizationTimepointDays) {
              timepoints.push(`${row.hospitalizationTimepointDays} days`);
            }

            // Subgroup details
            const subgroups = [
              row.outcomeSubgroupVariant
                ? `${row.outcomeSubgroupVariant} (${row.outcomeSubgroupVariantWho || 'N/R'})`
                : '',
              row.outcomeSubgroupComorbidities || '',
              row.outcomeSubgroupVaccinationStatus || '',
              row.outcomeSubgroupAgeYears ? `${row.outcomeSubgroupAgeYears} years old` : '',
              row.outcomeSubgroupOther || ''
            ]
              .filter(Boolean)
              .join(', ');

            // Add timepoints and subgroups
            if (timepoints.length > 0 && subgroups.length > 0) {
              parts.push(`${timepoints.join(', ')}${subgroups.length > 0 ? ', ' : ''}${subgroups}:`);
            } else if (timepoints.length > 0) {
              parts.push(`${timepoints.join(', ')}:`);
            } else if (subgroups.length > 0) {
              parts.push(`${subgroups}:`);
            }

            // Combine outcome metric with confidence intervals
            const outcomeMetricDetails =
              row.outcomeMetricLowerCi !== undefined && row.outcomeMetricUpperCi !== undefined
                ? `${row.outcomeMetric} (${row.outcomeMetricLowerCi} - ${row.outcomeMetricUpperCi})`
                : row.outcomeMetric; // Use just the outcome metric if CIs are not available

            parts.push(`${outcomeMetricDetails}; ${row.outcomeMetricPValue || 'N/R'}`);

            // Append special endpoints
            let endpoints = '';
            if (row.compositeEndpoint) {
              endpoints += `, ${row.compositeEndpoint}`;
            }
            if (row.hospitalizationEndpoint) {
              endpoints += `, ${row.hospitalizationEndpoint}`;
            }

            if (endpoints) {
              parts[parts.length - 1] += endpoints; // Append to the last part
            }

            // Join the parts and add a newline if this is not the first row
            tooltipContent += `${index > 0 ? '\n' : ''}${parts.join(' ').trim()}`; // Trim each part to avoid leading space
          });

          return tooltipContent.trim(); // Return the final tooltip, trimming any extra spaces
        },

        //tooltipContent = tooltipContent.slice(1);

        valueGetter: (params: ValueFormatterParams) => {
          const data = params.data;

          // Check if the outcome metric is missing and return empty if it is
          if (!data.outcomeMetric) {
            return 'N/A';
          }

          const parts = [];

          // Handle timepoints and subgroup details
          const timepoint = data.outcomeMetricTimepointDays ? `${data.outcomeMetricTimepointDays} days` : '';
          // Subgroup details with conditional display
          const subgroups = [
            data.outcomeSubgroupVariant
              ? `${data.outcomeSubgroupVariant} (${data.outcomeSubgroupVariantWho || 'N/R'})`
              : '',
            data.outcomeSubgroupComorbidities || '',
            data.outcomeSubgroupVaccinationStatus || '',
            data.outcomeSubgroupAgeYears ? `${data.outcomeSubgroupAgeYears} years old` : '', // Append " years old" if age is present
            data.outcomeSubgroupOther || ''
          ]
            .filter(Boolean)
            .join(', '); // Filter out empty strings
          // Combine timepoint and subgroup strings, and manage punctuation
          if (timepoint || subgroups) {
            parts.push(`${timepoint}${timepoint && subgroups ? ', ' : ''}${subgroups}`);
          }

          // Append colon directly after the last segment without an additional space
          if (parts.length > 0) {
            parts[parts.length - 1] += ':'; // Append colon directly to the last part
          }

          // Combine outcome metric with formatted confidence intervals
          const outcomeMetricDetails =
            data.outcomeMetricLowerCi !== undefined && data.outcomeMetricUpperCi !== undefined
              ? `${data.outcomeMetric} (${data.outcomeMetricLowerCi} - ${data.outcomeMetricUpperCi})`
              : data.outcomeMetric; // Use just the outcome metric if CIs are not available

          // Append the outcome metric details and p-value
          parts.push(`${outcomeMetricDetails}; ${data.outcomeMetricPValue || 'N/R'}`);

          // Append special composite other endpoint if not null, directly using the symbol stored in the data
          if (data.compositeOther) {
            parts.push(`, ${data.compositeOther}`);
          }

          return parts.join(' ').trim(); // Join all parts into one string and trim any excess whitespace
        }
      },
      {
        field: 'cohortUniqueness',
        headerName: 'Cohort Type',
        width: 250,
        filter: true,
        resizable: true,
        cellStyle: {'text-align': 'left'},
        tooltipField: 'cohortUniqueness',
        tooltipComponent: 'customTooltip'
      },
      {
        field: 'dataSource',
        headerName: 'DOI',
        width: 100,
        cellRenderer: 'datasourceRenderer'
      }
    ];
    this.columnDefs.forEach((x: any, i: any) => {
      x.cellStyle = x.cellStyle ?? {};
      x.cellStyle['background-color'] = i % 2 === 1 ? '#f5f5f5' : undefined;
    });
  }

  public markChecked = (params: any) => {
    return params.value === 'checked' || params.value === 'yes' ? '<i class="fas fa-check"></i>' : params.value;
  };

  ngOnInit(): void {
    this.activeTab = this.route.snapshot.data['activeTab'];

    const RWEDataPoints$ = this.variantApi.getRWEForestPlot();

    forkJoin([RWEDataPoints$]).subscribe(([RWEDataPoints]) => {
      this.allRWEPoints = RWEDataPoints; // Store all 241 rows

      // Reintroduce the filter to keep only unique covidenceNb rows
      this.selectedRWEPoints = Array.from(new Map(RWEDataPoints.map((item) => [item['covidenceNb'], item])).values());

      // Create a default SelectedRWEFilters object to pass for tooltip purposes
      const defaultFilters: SelectedRWEFilters = {
        filters: new Map<string, string[]>(), // Initialize an empty Map
        selectedCountries: [], // Default empty array for selected countries
        dateRange: {startDate: null, endDate: null} // Default null dates
      };

      // Apply filters on the 9 rows (filtered by covidenceNb)
      this.selectedRWEPoints = this.RWEFilters.filterPoints(this.selectedRWEPoints, defaultFilters, this.activeTab);

      this.rowCount = this.selectedRWEPoints.length;
      this.totalRows = this.allRWEPoints.length;
      RWEDataPoints.sort((a, b) => Date.parse(a.studyStartDate) - Date.parse(b.studyStartDate));
    });
  }

  public filterTable(filters: SelectedRWEFilters) {
    this.selectedRWEPoints = this.RWEFilters.filterPoints(this.allRWEPoints, filters, this.activeTab);
    this.rowCount = this.selectedRWEPoints.length;
  }

  public filterChanged(event: any) {
    this.rowCount = event.api.getDisplayedRowCount();
  }
}

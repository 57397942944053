import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {BivalentBoosterQuery} from '../models/dtos/bivalent-booster/bivalentbooster-ql';

@Injectable({
  providedIn: 'root'
})
export class BivalentBoosterQL extends Query<BivalentBoosterQuery, {}> {
  override document = gql`
    {
      bivalentboosters {
        id
        mvbReportNumber
        dataTitle
        dataSource
        sampleSource
        dataSourceType
        dataDate
        infectionHistory
        vacHistory
        vacBoost
        boostClass
        assayCat
        assayType
        assayTypeMod
        viralLineage
        vaccineActivity
        boost1TimeUnit
        boost1Time1
        boost1Effect1
        boost1Time2
        boost1Effect2
        boost1Time3
        boost1Effect3
      }
    }
  `;

  getBivalentBoostersByboostGlossid = gql`
    query BivalentBoosters($boostGlossid: String!) {
      bivalentboosters(boostGlossid: $boostGlossid) {
        id
        mvbReportNumber
        dataTitle
        dataSource
        sampleSource
        dataSourceType
        dataDate
        infectionHistory
        vacHistory
        vacBoost
        boostClass
        boostGlossid
        assayCat
        assayType
        assayTypeMod
        viralLineage
        vaccineActivity
        boost1TimeUnit
        boost1Time1
        boost1Effect1
        boost1Time2
        boost1Effect2
        boost1Time3
        boost1Effect3
      }
    }
  `;
}

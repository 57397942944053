import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import {forkJoin} from 'rxjs';
import {RWEForestPlotApiService} from '../../../services/api/rweforestplot-api/rweforestplot-api.service';
import {DateRange} from '../../../models/view-models/date-range';
import {RWEForestPlotPoint} from '../../../models/dtos/rweforestplot/rweforestplot';

@Component({
  selector: 'app-rwe-forestplot-filters',
  templateUrl: './rwe-forestplot-filters.component.html',
  styleUrls: ['./rwe-forestplot-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RWEForestPlotFiltersComponent implements OnInit {
  public filterValues: Map<string, string[]> = new Map<string, string[]>();
  public filterExpanded: Map<string, boolean> = new Map<string, boolean>();
  public selectedColFilters: Map<string, string[]> = new Map<string, string[]>();
  public dateExpanded = false;
  public dateRange!: DateRange;
  public dateRangeTxt!: string;
  public countrySearchText = '';
  public allCountries: string[] = [];
  public filteredCountries: string[] = [];
  public selectedCountries: string[] = [];
  public filters: any = {countries: {}}; // Add filters to track states
  public filterColumn: string[] = ['Treatment', 'Endpoint', 'Lineage (Variant)', 'Country'];
  @Input() activeTab: 'tabular' | 'forestplot' = 'tabular';
  @Output() filtersChanged = new EventEmitter<SelectedRWEFilters>();

  constructor(private RWEApi: RWEForestPlotApiService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.loadFilterData();
  }

  private loadFilterData(): void {
    const getRWE$ = this.RWEApi.getRWEForestPlot();
    forkJoin([getRWE$]).subscribe(([RWE]) => {
      this.mapApiFiltersToColumns(RWE); // Populate filterValues
      this.initializeSelectedFilters(); // Initialize selectedColFilters based on filterValues
      this.cdr.detectChanges(); // Manually trigger change detection to update the filters
    });
  }

  private updateFilterColumns(): void {
    if (this.activeTab === 'tabular') {
      this.filterColumn = ['Treatment', 'Endpoint', 'Lineage (Variant)'];
    } else if (this.activeTab === 'forestplot') {
      this.filterColumn = [
        'Treatment',
        'Endpoint',
        'Age',
        'Vaccination',
        'Variant',
        'Comorbidity',
        'Metric used',
        'Publication type',
        'Study type',
        'Composite outcomes',
        'Country'
      ];
    }
    this.initializeSelectedFilters();
    this.emitFilters();
  }

  private mapApiFiltersToColumns(points: RWEForestPlotPoint[]): void {
    // Clear previous filter values
    this.filterValues.clear();

    const treatmentFilterOptions = Array.from(
      new Set(
        points
          .flatMap((x) => [x.drug1, x.drug2])
          .filter((y): y is string => !!y && y !== 'N/A')
          .sort()
      )
    );
    const endpointFilterOptions = [
      ...new Set(
        points
          .flatMap((x) => [x.endpoint])
          .filter((y): y is string => !!y && y !== 'N/A')
          .sort()
      )
    ];
    const lineageFilterOptions = [
      ...new Set(
        points
          .flatMap((x) => [x.outcomeSubgroupVariant])
          .filter((y): y is string => !!y)
          .sort()
      )
    ];

    const ageFilterOptions = [
      ...new Set(
        points
          .flatMap((x) => [x.outcomeSubgroupAgeYears])
          .filter((y): y is string => !!y)
          .sort()
      )
    ];
    const vaccinationFilterOptions = [
      ...new Set(
        points
          .flatMap((x) => [x.outcomeSubgroupVaccinationStatus])
          .filter((y): y is string => !!y)
          .sort()
      )
    ];
    const variantFilterOptions = [
      ...new Set(
        points
          .flatMap((x) => [x.outcomeSubgroupVariant])
          .filter((y): y is string => !!y)
          .sort()
      )
    ];
    // Check if variantFilterOptions is empty and add "No Data" if true
    if (variantFilterOptions.length === 0) {
      variantFilterOptions.push('No Data');
    }
    const comorbidityFilterOptions = ['Diabetes', 'Immunosuppression']; // Only show the comorbidity types

    const metricFilterOptions = [
      ...new Set(
        points
          .flatMap((x) => [x.outcomeMetricType])
          .filter((y): y is string => !!y)
          .sort()
      )
    ];
    const pubFilterOptions = [
      ...new Set(
        points
          .flatMap((x) => [x.dataSourceType])
          .filter((y): y is string => !!y)
          .sort()
      )
    ];

    const studyFilterOptions = ['Clinical Trial (Interventional)', 'Real-World Evidence (Observational)'];

    const compositeFilterOptions = [
      ...new Set(points.flatMap((x) => [x.endpointComposite]).filter((y): y is string => !!y))
    ];

    const countryFilterOptions = [
      ...new Set(
        points
          .flatMap((x) => (x.studyCountry ? x.studyCountry.split(',') : []))
          .map((c) => c.trim())
          .filter((y): y is string => !!y)
          .sort()
      )
    ];

    this.allCountries = countryFilterOptions;
    this.filteredCountries = [...this.allCountries]; // Initialize filtered list with all countries

    this.setColumnValuesFromApi('Treatment', treatmentFilterOptions);
    this.setColumnValuesFromApi('Endpoint', endpointFilterOptions);

    if (this.activeTab === 'forestplot') {
      this.setColumnValuesFromApi('Age', ageFilterOptions);
      this.setColumnValuesFromApi('Vaccination', vaccinationFilterOptions);
      this.setColumnValuesFromApi('Variant', variantFilterOptions);
      this.setColumnValuesFromApi('Comorbidity', comorbidityFilterOptions);
      this.setColumnValuesFromApi('Metric used', metricFilterOptions);
      this.setColumnValuesFromApi('Publication type', pubFilterOptions);
      this.setColumnValuesFromApi('Study type', studyFilterOptions);
      this.setColumnValuesFromApi('Composite outcomes', compositeFilterOptions);
      this.setColumnValuesFromApi('Country', countryFilterOptions);
    } else if (this.activeTab === 'tabular') {
      this.setColumnValuesFromApi('Lineage (Variant)', lineageFilterOptions);
    }
  }

  initializeSelectedFilters(): void {
    // Clear any previous filters
    this.selectedColFilters.clear();

    // Initialize selected filters with empty arrays for each filter column
    this.filterValues.forEach((_, key) => {
      if (!this.selectedColFilters.has(key)) {
        this.selectedColFilters.set(key, []); // Set each column as an empty array
      }
    });
  }

  private setColumnValuesFromApi(columnName: string, recordDB: string[]): void {
    // Ensure that filterValues is being set correctly
    if (!this.filterValues.has(columnName)) {
      this.filterValues.set(columnName, []); // Initialize the column in filterValues if not present
    }
    this.filterValues.get(columnName)?.push(...recordDB); // Populate the column with filter values
  }

  public changeDate(dateInfo: any): void {
    this.dateRange = dateInfo;
    this.emitFilters();
  }

  private emitFilters(): void {
    this.filtersChanged.emit({
      filters: this.selectedColFilters,
      selectedCountries: this.selectedCountries,
      dateRange: this.dateRange
    });
  }

  public toggleFilter(filterValue: string, column: string): void {
    const dataList = this.selectedColFilters.get(column);

    if (dataList) {
      if (column === 'Comorbidity') {
        // Special case for comorbidity
        const yesFilter = `${filterValue}: Yes`;
        const index = dataList.indexOf(yesFilter);

        if (index !== -1) {
          // Unchecking the filter: remove `Diabetes: Yes` or `Immunosuppression: Yes`
          dataList.splice(index, 1);
        } else {
          // Checking the filter: add `Diabetes: Yes` or `Immunosuppression: Yes`
          dataList.push(yesFilter);
        }
      } else if (column === 'Study type') {
        // Special case for Study type (Clinical Trial and Real-World Evidence)
        if (filterValue === 'Clinical Trial (Interventional)') {
          const yesFilter = 'Yes';
          const index = dataList.indexOf(yesFilter);

          if (index !== -1) {
            // Unchecking the filter: remove `Yes`
            dataList.splice(index, 1);
          } else {
            // Checking the filter: add `Yes`
            dataList.push(yesFilter);
          }
        } else if (filterValue === 'Real-World Evidence (Observational)') {
          const noFilter = 'No';
          const index = dataList.indexOf(noFilter);

          if (index !== -1) {
            // Unchecking the filter: remove `No`
            dataList.splice(index, 1);
          } else {
            // Checking the filter: add `No`
            dataList.push(noFilter);
          }
        }
      } else {
        // Standard filtering logic for other columns
        const index = dataList.indexOf(filterValue);
        if (index !== -1) {
          // Unchecking the filter
          dataList.splice(index, 1);
        } else {
          // Checking the filter
          dataList.push(filterValue);
        }
      }

      // Update the selected filters map and trigger change detection
      this.selectedColFilters.set(column, [...dataList]);
      this.cdr.markForCheck(); // This will force the UI to refresh

      this.emitFilters(); // Emit the filters for further use
    } else {
      console.warn(`No dataList found for column: ${column}`);
    }
  }

  public trackByCountry(index: number, country: string): string {
    return country;
  }

  public trackByFilterEntry(index: number, filterEntry: [string, string[]]): string {
    return filterEntry[0]; // Track by the key of the filter entry (e.g., 'Treatment', 'Country')
  }

  toggleFilterExpanded(key: string): void {
    const current = this.filterExpanded.get(key);
    this.filterExpanded.set(key, !current);
    this.cdr.markForCheck();
  }

  isFilterExpanded(key: string): boolean {
    const expanded = this.filterExpanded.get(key) || false;
    return expanded;
  }

  public filterCountryList() {
    this.filteredCountries = this.allCountries
      .filter((country) => country !== 'United States of America')
      .filter((country) => country.toLowerCase().startsWith(this.countrySearchText.toLowerCase()));
  }

  public isCountrySelected(country: string): boolean {
    return this.selectedCountries.includes(country);
  }
  public onCountryChange(event: Event, country: string): void {
    const inputElement = event.target as HTMLInputElement;
    const checked = inputElement?.checked || false;

    if (checked) {
      if (!this.selectedCountries.includes(country)) {
        this.selectedCountries.push(country);
      }
    } else {
      this.selectedCountries = this.selectedCountries.filter((c) => c !== country);
    }

    this.emitFilters();
  }

  public removeCountry(country: string): void {
    this.selectedCountries = this.selectedCountries.filter((c) => c !== country);
    this.emitFilters();
  }

  public get filterEntries(): [string, string[]][] {
    return Array.from(this.filterValues.entries());
  }
}

export interface SelectedRWEFilters {
  filters: Map<string, string[]>;
  selectedCountries: string[];
  dateRange: DateRange;
}

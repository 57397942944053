<div>
  <div id="filter" class="bg-white p-3 px-1">
    <div class="d-block border-bottom">
      <h5>FILTER BY</h5>
    </div>

    <!-- Loop through filterEntries once -->
    <div *ngFor="let filterEntry of filterEntries; let idx = index; trackBy: trackByFilterEntry">
      <div class="border-top py-3">
        <!-- Toggle Expand/Collapse using filterEntry[0] for the key -->
        <div class="column-name" (click)="toggleFilterExpanded(filterEntry[0])">
          <span>{{ filterEntry[0] }}</span>
          <div>
            <span *ngIf="!isFilterExpanded(filterEntry[0])" class="ml-3 expand-arrow arrow-right">
              <i class="fas fa-angle-down"></i>
            </span>
            <span *ngIf="isFilterExpanded(filterEntry[0])" class="ml-3 expand-arrow arrow-right">
              <i class="fas fa-angle-up"></i>
            </span>
          </div>
        </div>

        <!-- Special case for "Country" filter -->
        <div *ngIf="filterEntry[0] === 'Country' && isFilterExpanded(filterEntry[0])" class="filter-values py-2 px-1">
          <!-- Checkbox for United States -->
          <label class="check-label">
            <input
              type="checkbox"
              class="checkmark"
              [checked]="isCountrySelected('United States of America')"
              (change)="onCountryChange($event, 'United States of America')"
            />
            <div class="filter-window" title="United States">
              <div>United States of America</div>
            </div>
          </label>

          <!-- Autocomplete text input for other countries -->
          <input
            type="text"
            [(ngModel)]="countrySearchText"
            (input)="filterCountryList()"
            class="form-control my-2"
            placeholder="Search countries"
          />

          <!-- List of filtered countries based on search -->
          <div *ngFor="let country of filteredCountries; trackBy: trackByCountry">
            <label class="check-label">
              <input
                type="checkbox"
                class="checkmark"
                [checked]="isCountrySelected(country)"
                (change)="onCountryChange($event, country)"
              />
              <div class="filter-window" [title]="country">
                <div>{{ country }}</div>
              </div>
            </label>
          </div>

          <!-- Display selected country bubbles -->
          <div class="selected-countries">
            <span *ngFor="let country of selectedCountries" class="selected-country-bubble">
              {{ country }}
              <button type="button" class="remove-btn" (click)="removeCountry(country)">x</button>
            </span>
          </div>
        </div>

        <!-- Default filter structure for other filters -->
        <div *ngIf="isFilterExpanded(filterEntry[0]) && filterEntry[0] !== 'Country'" class="filter-values py-2 px-1">
          <div *ngFor="let filterId of filterEntry[1]; let i = index">
            <label class="check-label">
              <input
                type="checkbox"
                class="checkmark"
                [checked]="selectedColFilters.get(filterEntry[0])?.includes(filterId)"
                (change)="toggleFilter(filterId, filterEntry[0])"
              />

              <div class="filter-window" [title]="filterId">
                <div>{{ filterId }}</div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>

    <!-- Date filter section -->
    <div *ngIf="dateExpanded">
      <input
        id="reported-date"
        type="text"
        class="p-1 pl-2 mb-2"
        ngxDaterangepickerMd
        [(ngModel)]="dateRangeTxt"
        placeholder="Choose date range"
        (change)="changeDate($event)"
        #picker
      />
    </div>
  </div>
</div>

<p>&nbsp;</p>

<div class="col-md-12 note">
  <div><strong>*</strong> = Composite outcome for this metric</div>
  <div><strong>**</strong> = Composite of therapeutics in outcome metric</div>
  <div><strong>†</strong> = COVID-19 related hospitalization and/or mortality</div>
  <div><strong>‡</strong> = All-cause hospitalization and/or mortality</div>
  <div><strong>&sect;</strong> = Other composite outcomes</div>
  <div><strong>N/A</strong> = Not applicable</div>
  <div><strong>N/R</strong> = Not reported</div>
</div>

<!-- Forest plot key section -->
<div *ngIf="activeTab === 'forestplot'">
  <div class="col-md-12 forest-plot-key">
    <h4>Forest Plot Key:</h4>
    <ul>
      <li class="hazard-ratio">Hazard Ratio</li>
      <li class="adjusted-hazard-ratio">Adjusted Hazard Ratio</li>
      <li class="unadjusted-hazard-ratio">Unadjusted Hazard Ratio</li>
      <li class="adjusted-odds-ratio">Adjusted Odds Ratio</li>
      <li class="odds-ratio">Odds Ratio</li>
      <li class="odds-ratio-estimates">Odds Ratio Estimates</li>
      <li class="adjusted-risk-ratio">Adjusted Risk Ratio</li>
      <li class="recovery-rate-ratio">Recovery Rate Ratio</li>
      <li class="recovery-rate-or-hazard-ratio">Recovery Rate Ratio or Hazard Ratio</li>
      <li class="absolute-risk-difference">Absolute Risk Difference</li>
      <li class="difference-se-from-placebo">Difference (+SE) from Placebo</li>
      <li class="difference-from-placebo">Difference from Placebo</li>
    </ul>
  </div>
</div>

import {Injectable} from '@angular/core';
import {RWEForestPlotPoint} from '../../../models/dtos/rweforestplot/rweforestplot';
import {SelectedRWEFilters} from '../rwe-forestplot-filters/rwe-forestplot-filters.component';

@Injectable({
  providedIn: 'root'
})
export class RWEForestPlotFilterService {
  constructor() {}

  public filterPoints(
    allRWEPoints: RWEForestPlotPoint[],
    filters: SelectedRWEFilters,
    activeTab: 'tabular' | 'forestplot'
  ): RWEForestPlotPoint[] {
    if (!Array.isArray(allRWEPoints)) {
      return [];
    }

    const result = [];
    const colfilters = filters.filters;
    const selectedCountries = filters.selectedCountries || []; // Add country filter

    for (const record of allRWEPoints) {
      let includeRecord = false;
      if (activeTab === 'tabular') {
        includeRecord =
          (this.searchInFields(colfilters.get('Treatment') ?? [], String(record.drug1)) ||
            this.searchInFields(colfilters.get('Treatment') ?? [], String(record.drug2))) &&
          this.searchInFields(colfilters.get('Endpoint') ?? [], record.endpoint) &&
          (this.searchInFields(colfilters.get('Lineage (Variant)') ?? [], String(record.outcomeSubgroupVariant)) ||
            this.searchInFields(colfilters.get('Lineage (Variant)') ?? [], String(record.outcomeSubgroupVariantWho)));
      } else if (activeTab === 'forestplot') {
        includeRecord =
          (this.searchInFields(colfilters.get('Treatment') ?? [], String(record.drug1)) ||
            this.searchInFields(colfilters.get('Treatment') ?? [], String(record.drug2))) &&
          this.searchInFields(colfilters.get('Endpoint') ?? [], record.endpoint) &&
          this.searchInFields(colfilters.get('Age') ?? [], String(record.outcomeSubgroupAgeYears)) &&
          this.searchInFields(colfilters.get('Vaccination') ?? [], String(record.outcomeSubgroupVaccinationStatus)) &&
          this.searchInFields(colfilters.get('Variant') ?? [], String(record.outcomeSubgroupVariant)) &&
          this.searchInFields(colfilters.get('Comorbidity') ?? [], String(record.outcomeSubgroupComorbidities)) &&
          this.searchInFields(colfilters.get('Metric used') ?? [], String(record.outcomeMetricType)) &&
          this.searchInFields(colfilters.get('Publication type') ?? [], String(record.dataSourceType)) &&
          this.searchInFields(colfilters.get('Study type') ?? [], String(record.randomizedControlledTrial)) &&
          this.searchInFields(colfilters.get('Composite outcomes') ?? [], String(record.endpointComposite)) &&
          this.searchInCountries(selectedCountries, String(record.studyCountry));
      }

      if (includeRecord) {
        result.push(record);
      }
    }

    return result;
  }

  private searchInCountries(selectedCountries: string[], record: string): boolean {
    if (!selectedCountries.length) {
      return true; // No country filter, include all records
    }

    // Split the record's studyCountry field by commas or semicolons
    const recordCountries = record.split(/[,;]/).map((country) => country.trim());

    // Return true if any of the selected countries match any of the record countries
    return selectedCountries.some((selectedCountry) => recordCountries.includes(selectedCountry.trim()));
  }

  private searchInFields(data: string[], record: string) {
    if (!data.length) {
      return true;
    }
    let found = false;
    for (const d of data) {
      if (record !== null && record !== undefined) {
        for (const d of data) {
          if (d.trim() === record.trim()) {
            found = true;
            break;
          }
        }
      }
      if (record.indexOf(';') > -1) {
        const entries = record.split(';');
        for (const entry of entries) {
          if (d.trim() === entry.trim()) {
            found = true;
          }
        }
      }
      if (record.indexOf(',') > -1) {
        const entries = record.split(',');
        for (const entry of entries) {
          if (d.trim() === entry.trim()) {
            found = true;
          }
        }
      }
    }
    return found;
  }
}

import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {AssayDetailsQuery} from '../models/dtos/assay-details/assay-details-ql';

@Injectable({
  providedIn: 'root'
})
export class AssayDetailsQL extends Query<AssayDetailsQuery, {}> {
  override document = gql`
    {
      cov2assays {
        id
        assayName
        assayType
        category
        detectionType
        active
        doi
        assayDataFileUrl
        assayId
      }
    }
  `;

  getAssayPerformance = gql`
    query getAssayPerformanceByAssayIDLibraryID($assayID: Int!, $libraryID: Int!) {
      getAssayPerformanceByAssayIDLibraryID(assayID: $assayID, libraryID: $libraryID) {
        id
        assayName
        libraryId
        assayProtocolId
        protocolName
        plateInfoId
        plateId
        typeIndex
        bI
        cvDmso
        zNi
      }
    }
  `;

  getSingleAssay = gql`
    query cov2assays($id: ID!) {
      cov2assays(id: $id) {
        id
        assayName
        assayId
        targetCategory
        target
        assayType
        detectionType
        dateScreened
        throughput
        assayOverview
        protocolDescription
        interpretation
        assayDataFileUrl
        assayProtocolFile
        assayScreeningFile
        matrixFileId
        cov2Protocol {
          id
          assayProtocolId
          qhtsLibrary
          cov2Library {
            id
            library
            libraryShort
            libraryDescription
          }
        }
      }
    }
  `;

  getMatrix = gql`
    query matricess($id: String!) {
      matricess(matrixid: $id) {
        edges {
          node {
            id
            matrixid
            matrixlink
            projectname
            associatedcounterscreens
            associatedcounterscreenslink
            cellline
            detectiontype
            numberofcombination
            datescreened
            collaborators
            assay1
            assay1link
            assay2
            assay2link
            normalization
            highlights
            publication
            createdBy
            updatedBy
            createdAt
            updatedAt
          }
        }
      }
    }
  `;
}

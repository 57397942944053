<div class="w100 content-area">
  <app-rwe-forestplot-header></app-rwe-forestplot-header>
  <app-fixed-menu></app-fixed-menu>
  <div class="mt-4 mv-2 rweforestplot-container nav-container">
    <nav mat-tab-nav-bar [tabPanel]="tabPanel">
      <a
        mat-tab-link
        routerLinkActive
        #rla1="routerLinkActive"
        [routerLink]="['./data']"
        [active]="rla1.isActive"
        (click)="setActiveTab('tabular')"
      >
        <h4>View Summary Table of Clinical Studies</h4>
      </a>
      <a
        mat-tab-link
        routerLinkActive
        #rla2="routerLinkActive"
        [routerLink]="['./tool']"
        [active]="rla2.isActive"
        (click)="setActiveTab('forestplot')"
      >
        <h4>View Clinical Outcome Data</h4>
      </a>
    </nav>
    <div mat-tab-nav-panel #tabPanel></div>
    <div class="rweforestplot-page">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import {Component, OnInit, ElementRef, AfterViewInit, ViewEncapsulation} from '@angular/core';
import * as d3 from 'd3';
import {ICustomHeaderParams} from './custom-header-params.interface'; // Import the custom interface
import {NumberValue} from 'd3';

@Component({
  selector: 'app-d3-header',
  templateUrl: './rwe-forestplot-axis-header.component.html',
  styleUrls: ['./rwe-forestplot-axis-header.component.scss'],
  encapsulation: ViewEncapsulation.None // Add this to prevent Angular from scoping the styles
})
export class RWEForestPlotAxisHeaderComponent implements OnInit, AfterViewInit {
  private params: ICustomHeaderParams | undefined;
  private width = 340; // Width of the header
  private height = 40; // Height of the header, adjusted to fit the axis and text
  private paddingLeft = 10; // Match padding with forest plot cell
  private paddingRight = 10; // Adjust right padding

  // Initialize these properties
  private globalMinCI: number = 0.01;
  private globalMaxCI: number = 100;
  private plotType: string = 'ratios'; // Default to "ratios"

  public headerText: string = 'Forest plot'; // default

  constructor(private hostElement: ElementRef) {}

  ngOnInit(): void {
    // This lifecycle hook is intentionally left empty.
  }

  ngAfterViewInit(): void {
    // This lifecycle hook is intentionally left empty.
  }

  agInit(params: ICustomHeaderParams): void {
    this.params = params;
    this.headerText = params.displayName;

    if (params.globalMinCI !== undefined && params.globalMaxCI !== undefined) {
      this.globalMinCI = params.globalMinCI;
      this.globalMaxCI = params.globalMaxCI;
    }

    const element = this.hostElement.nativeElement.querySelector('.ag-header-cell');
    if (element) {
      element.style.paddingLeft = '10px'; // Adjust padding to prevent jamming
      element.style.paddingRight = '10px';
    }

    // Render axis
    this.renderD3Axis();
  }

  private renderD3Axis(): void {
    const element = this.hostElement.nativeElement.querySelector('.d3-axis');
    d3.select(element).selectAll('svg').remove(); // Clear the container first

    const svg = d3
      .select(element)
      .append('svg')
      .attr('width', '100%')
      .attr('height', this.height + 10); // Increased height by 10

    // Ensure the svg element gets the correct bounding width
    const actualWidth = svg.node()?.getBoundingClientRect().width || this.width;

    let scale: d3.ScaleLinear<number, number> | d3.ScaleLogarithmic<number, number> | undefined = undefined;

    // Determine the scale based on plot type
    if (this.params?.plotType === 'ratios') {
      // Logarithmic scale for "ratios"
      scale = d3
        .scaleLog()
        .domain([Math.max(0.01, this.globalMinCI * 0.9), this.globalMaxCI * 1.1])
        .range([this.paddingLeft, actualWidth - this.paddingRight - 15]); // Added 15px padding on the right
    } else if (this.params?.plotType === 'differences') {
      // Linear scale for "differences"
      scale = d3
        .scaleLinear()
        .domain([-20, 20])
        .range([this.paddingLeft, actualWidth - this.paddingRight - 15]); // Added 15px padding on the right
    }

    // Ensure the scale is defined before using it
    if (!scale) {
      console.error('Scale is not defined for this plotType:', this.params?.plotType);
      return; // Exit if scale is not assigned properly
    }

    // Custom tick format function for "ratios"
    const tickFormat = (d: NumberValue) => {
      const n = d.valueOf();
      if (n === 0.01) return '0.01';
      if (n === 0.1) return '0.1';
      if (n === 1) return '1.0';
      if (n === 10) return '10.0';
      if (n === 100) return '100.0';
      return n.toString();
    };

    // Choose tick values based on plot type
    const tickValues = this.params?.plotType === 'ratios' ? [0.01, 0.1, 1, 10, 100] : [-20, -10, 0, 10, 20];

    // Apply tick formatting only for ratios
    const xAxis = d3.axisBottom(scale).tickValues(tickValues);
    if (this.params?.plotType === 'ratios') {
      xAxis.tickFormat(tickFormat);
    }

    svg
      .append('g')
      .attr('transform', `translate(0, ${this.height - 30})`) // Adjust based on your height and positioning
      .call(xAxis);

    // Adjust the font size for axis labels
    svg.selectAll('text').attr('font-size', '12px').attr('dy', '0.65em').attr('dx', '-0.5em');
  }
}

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
<app-header-view></app-header-view>
<app-menu></app-menu>
<div class="container-fluid hero">
  <div class="container py-4">
    <div class="row">
      <div class="col-12 page-title">
        <h2>OpenData Portal | SARS-CoV-2 Variants & Therapeutics</h2>
        <h3 class="subheading">
          Dataset Browser
          <span class="btn btn-primary bg-white text-dark border-0 ml-3" *ngIf="!dataLoading">
            Updated {{ getUpdateText() }}
          </span>
        </h3>
        <div class="d-flex pb-4">
          <div class="w-60">
            <div class="scores">
              <div>
                <span class="score">{{ dataSourceCount }}</span> data sources
              </div>
              <div class="ml-2">
                <span class="score">{{ recordCount }}</span> activity data points
              </div>
            </div>
          </div>
        </div>
        <p>
          OpenData Portal, in collaboration with ACTIV and industry partners, has compiled a database of in vitro
          therapeutic activity against SARS-CoV-2 variants from a prioritized set of publications (both preprints and
          peer-reviewed articles).
        </p>
        <a
          href="https://opendata.ncats.nih.gov/public/odp/TRACE-INVITRO-DATA.zip"
          class="btn btn-primary bg-white download-all"
          *ngIf="!dataLoading"
        >
          Download the in vitro datasets here
        </a>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row py-3 dataset px-3">
    <div class="col-9 p-0">
      <p class="py-3 mb-0"><a [routerLink]="['/covid19']">Home </a> > Variant Therapeutic in vitro Dataset Browser</p>
    </div>
    <div class="col-3 text-right d-none d-md-block d-lg-block">
      <a href="https://fnih.org/" target="_blank">
        <img width="150" src="./assets/images/fnih.png" alt="" />
      </a>
    </div>
  </div>
</div>

<div class="table-container container-fluid py-4">
  <div class="container">
    <div class="row">
      <div class="col-12 offset-md-3 col-md-8"><h3>SARS-CoV-2 Variant Dataset Browser</h3></div>
      <div class="col-md-3 col-sm-12 table-responsive">
        <div id="filter" class="bg-white p-3 px-1 table-responsive">
          <div class="d-block border-bottom table-responsive">
            <h5>FILTER BY</h5>
          </div>
          <span *ngFor="let col of filterColumn" class="w-auto">
            <div class="border-bottom py-3">
              <div class="columnName" (click)="filterExpanded.set(col, !filterExpanded.get(col))">
                {{ col }}:
                <span *ngIf="!filterExpanded.get(col)" class="ml-3 expand-arrow arrow-right">
                  <i class="fa fa-chevron-down"></i>
                </span>
                <span *ngIf="filterExpanded.get(col)" class="ml-3 expand-arrow arrow-right">
                  <i class="fa fa-chevron-up"></i>
                </span>
              </div>
              <div *ngIf="filterExpanded.get(col)" class="py-2 px-1">
                <span *ngIf="filterColumnWithSearch.includes(col)">
                  <input
                    #dataSelection
                    class="filterSearch"
                    placeholder="Search for {{ col }}"
                    (input)="searchFilterSelections(col, dataSelection.value)"
                  />
                </span>
                <div class="filterInnerColumns">
                  <span *ngFor="let item of filterAfterSearch.get(col)">
                    <div>
                      <label>
                        {{ item.value }}
                        <input
                          type="checkbox"
                          class="checkmark"
                          id="{{ item.value }}"
                          [checked]="item.selected"
                          (change)="filterOnChange(item.value, col)"
                        />
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </span>
          <div class="border-bottom py-3">
            <div class="columnName" (click)="mutationExpanded = !mutationExpanded">
              Mutation:
              <span *ngIf="!mutationExpanded" class="ml-3 expand-arrow arrow-right">
                <i class="fa fa-chevron-down"></i>
              </span>
              <span *ngIf="mutationExpanded" class="ml-3 expand-arrow arrow-right">
                <i class="fa fa-chevron-up"></i>
              </span>
            </div>
            <span *ngIf="mutationExpanded">
              <input
                type="text"
                #mutationFilterValue
                placeholder="Filter for mutations"
                (input)="handleFilterOnChangeEvent(mutationFilterValue.value)"
                value="{{ mutationQuery }}"
                id="mutation-filter-input"
                class="form-control"
              />
              <div id="filter-selector" *ngIf="mutationDisplay">
                <span *ngFor="let m of mutationFilterAutoComplete">
                  <div id="filter-keywords-container" (click)="addMutationFilter(m); mutationFilterValue.value = ''">
                    <div class="mutation-choice">{{ m }}</div>
                  </div>
                </span>
              </div>
            </span>
          </div>
          <div class="border-bottom py-2">
            <div class="columnName" (click)="dateExpanded = !dateExpanded">
              Report Date Range:
              <span *ngIf="!dateExpanded" class="ml-3 expand-arrow arrow-right">
                <i class="fa fa-chevron-down"></i>
              </span>
              <span *ngIf="dateExpanded" class="ml-3 expand-arrow arrow-right">
                <i class="fa fa-chevron-up"></i>
              </span>
            </div>
          </div>
          <span *ngIf="dateExpanded">
            <input
              id="reported-date"
              type="text"
              class="p-1 pl-2 mb-2"
              ngxDaterangepickerMd
              [(ngModel)]="dateRange"
              placeholder="Choose date range"
              (apply)="changeDate($event)"
              (change)="changeDate($event)"
              (keydown.enter)="changeDate($event)"
              #pickerRange
              [showCancel]="true"
            />
          </span>
        </div>
      </div>
      <div class="col-md-9 co-sm-12">
        <div id="input-wrapper" class="mb-2">
          <i class="fa fa-search" id="search-icon"></i>
          <input
            #searchInput
            placeholder="Search Dataset Titles and Press Enter"
            [(ngModel)]="query"
            id="input-box"
            class="my-2"
            (keyup)="$event.keyCode === 13 ? getSearchResult(searchInput.value) : null"
          />
          <i class="fa fa-times ml-3" id="clear-search" (click)="clearSearchKeywords()"></i>

          <i class="fa fa-times ml-3" id="clear-search" (click)="clearSearchKeywords()"></i>

          <span (click)="handleOnclickEvent(searchInput.value)">
            <div id="search-selector" *ngIf="display">
              <div id="keywords-container">Search {{ keyWords }}</div>
            </div>
          </span>
        </div>

        <span *ngIf="result">
          <div id="ds-number">
            {{ DSDisplay ? totalVariants : 0 }} record(s) found
            <button id="copy-link" [cdkCopyToClipboard]="getShareLink()" (click)="copy()">
              &nbsp;<i class="fa fa-link"></i>&nbsp;Copy link to results&nbsp;
            </button>
            <span *ngIf="displayCopied" id="copy-success" class="bubble bubble-bottom-left">
              <span id="copied">Copied to clipboard!</span>
            </span>
          </div>
        </span>

        <!-- hide pagninator if url has list of ids -->
        <span *ngIf="idList.length === 0">
          <div class="paginator-container">
            <mat-paginator
              [length]="totalVariants"
              [pageSize]="pageSize"
              [pageSizeOptions]="[5, 10, 20]"
              [pageIndex]="currentPage - 1"
              (page)="onPageChange($event.pageIndex + 1)"
              [disabled]="dataLoading"
            >
            </mat-paginator>
          </div>
        </span>

        <span *ngFor="let i of displayFilterBy()">
          <div id="filter-by" class="d-inline">
            <div class="filterItems d-inline">
              <span class="badge badge-light p-2 px-3 m-1" id="rm-filter" (click)="removeFilter(i[0], i[1])">
                {{ i[0] }}: {{ i[1] }} <i class="fa fa-times ml-3"></i>
              </span>
            </div>
          </div>
        </span>
        <span
          *ngIf="displayFilterBy().length"
          id="clear-filter"
          class="badge-light p-2 px-3 m-1"
          (click)="clearAllFilters()"
        >
          Clear all
        </span>

        <!-- Desktop View-->
        <table *ngIf="!!result && !dataLoading; else nodata" class="w-100">
          <tr>
            <td>
              <div class="w-100" id="no-result" *ngIf="noResult">Your search did not match any datasets.</div>
              <div id="DS-table" *ngIf="DSDisplay">
                <div class="bg-white my-4 p-3 item-outer-container" *ngFor="let dataset of result">
                  <div id="DS-main" class="row mb-3">
                    <div class="col-12">
                      <div id="DS-info">
                        <div class="row item-container">
                          <div class="col-12 col-md-9">
                            <div id="DS-title-info">
                              <div id="DS-title">
                                {{ dataset.reportName.length ? dataset.reportName : 'No Data' }}
                              </div>
                              <div id="variant-lineage">
                                <span
                                  id="vl-list"
                                  *ngFor="let vl of dataset.viralLineages?.length ? dataset.viralLineages : ['No Data']"
                                >
                                  <span id="vl" [ngStyle]="{'background-color': getVLColor(vl)}">{{ vl }}</span
                                  >&nbsp;
                                </span>
                              </div>
                              <div id="text-info">
                                <table id="info-table">
                                  <tr valign="top">
                                    <td>Viral Agent Type</td>
                                    <td>
                                      <span
                                        *ngFor="
                                          let vat of dataset.viralAgentType?.length
                                            ? dataset.viralAgentType
                                            : ['No Data']
                                        "
                                        id="vat-list"
                                      >
                                        {{ vat }}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr valign="top">
                                    <td>Mutations</td>
                                    <td>
                                      <span
                                        *ngFor="let m of dataset.mutations?.length ? dataset.mutations : ['No Data']"
                                        id="mutation-list"
                                      >
                                        {{ m }}
                                      </span>
                                    </td>
                                  </tr>
                                  <tr valign="top">
                                    <td>Therapeutic Agents</td>
                                    <td>
                                      <span
                                        *ngFor="
                                          let ta of dataset.drugNames?.length ? dataset.drugNames.sort() : ['No Data']
                                        "
                                      >
                                        <span>{{ ta }}</span
                                        ><br />
                                      </span>
                                    </td>
                                  </tr>
                                  <tr valign="top">
                                    <td>Assay Type</td>
                                    <td>
                                      <span
                                        *ngFor="let at of dataset.assayTypes?.length ? dataset.assayTypes : ['No Data']"
                                        id="at-link"
                                      >
                                        <a [routerLink]="linkToAssay(at)">{{ at }}&nbsp;</a>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr valign="top">
                                    <td>Therapeutic Activity Data</td>
                                    <td #tableEl (click)="expandedTable.set(dataset, !expandedTable.get(dataset))">
                                      <span class="dataViewerBtn p-1 px-3 rounded">
                                        <span *ngIf="!expandedTable.get(dataset)" class="expand-arrow">
                                          Show data table <i class="fa fa-chevron-down"></i>
                                        </span>
                                        <span *ngIf="expandedTable.get(dataset)" class="expand-arrow">
                                          Hide data table <i class="fa fa-chevron-up"></i>
                                        </span>
                                      </span>
                                    </td>
                                  </tr>
                                </table>

                                <app-dataviewer
                                  *ngIf="expandedTable.get(dataset)"
                                  [variants]="dataset.variants"
                                  [reportNumber]="dataset.reportId"
                                  [expandableContainer]="dataset.viralLineages"
                                ></app-dataviewer>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-3">
                            <div id="DS-download">
                              <a
                                id="download-button-container"
                                class="d-flex"
                                *ngIf="dataset.dataFileDownloadUrl"
                                [href]="apiBaseUrl + dataset.dataFileDownloadUrl"
                                [download]
                              >
                                <i class="fa fa-download pt-1 pr-1" id="download-icon"></i>
                                <div id="download-button">DOWNLOAD</div>
                              </a>
                              <div id="view-source-button-container" class="text-center" *ngIf="dataset.dataSourceUrls">
                                <span *ngIf="dataset.dataSourceUrls.length === 1">
                                  <div id="view-source-button">
                                    <app-external-link
                                      [url]="dataset.dataSourceUrls[0]"
                                      [text]="'View Source'"
                                    ></app-external-link>
                                  </div>
                                </span>
                                <span *ngIf="dataset.dataSourceUrls.length !== 1">
                                  <div
                                    id="view-source-button"
                                    (click)="viewSource.set(dataset, !viewSource.get(dataset))"
                                  >
                                    View Source
                                  </div>
                                </span>
                                <span *ngIf="viewSource.get(dataset)">
                                  <span *ngFor="let url of dataset.dataSourceUrls; index as i">
                                    <div id="view-source-choice-container">
                                      <div class="view-source-choice">
                                        <app-external-link
                                          [url]="url"
                                          [text]="'Source ' + (i + 1).toString()"
                                        ></app-external-link>
                                      </div>
                                    </div>
                                  </span>
                                </span>
                              </div>
                              <div id="data-source-type"></div>
                              <div id="reported">Reported</div>
                              <div id="reported-date">
                                {{ dataset.dateReported.length ? dataset.dateReported : 'No Data' }}
                              </div>
                              <div id="uploaded">Last Updated</div>
                              <div id="uploaded-date">
                                {{ dataset.dateUploaded.length ? dataset.dateUploaded : 'No Data' }}
                              </div>
                              <div id="source">Affiliations</div>
                              <div id="submitter">
                                {{ dataset.dataProvider.length ? dataset.dataProvider : 'No Data' }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>

<ng-template #nodata>
  <div class="col-12 my-4 d-flex justify-content-center nodata">
    <div *ngIf="!error">
      <app-loader></app-loader>
    </div>
    <div *ngIf="error" class="error-msg">An error has occurred. Please try again later.</div>
  </div>
  <app-footer-view [showCC]="true"></app-footer-view>
</ng-template>

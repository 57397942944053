app-time-points-chart {
    * {
      font-size: 0.85rem;
    }
    .h4 {
      font-size: 1.5rem;
      & * {
        font-size: 1.5rem;
      }
    }
    // svg styles
    text {
      font-size: 0.8rem;
    }
    line {
      stroke: #bbb;
    }
    .global-axes .tick line {
      stroke: white;
    }
    .global-axes .tick:nth-child(9n + 2) line {
      stroke: #999;
    }
  
    .grouped-chart .axis-y path,
    .grouped-chart .axis-y line {
      stroke: white;
    }
    .x-axis line {
      stroke: #999;
    }
    .axis-y text {
      font: 1rem sans-serif;
      cursor: pointer;
    }
    .axis-y .tick:hover {
      color: #d40;
    }
    .y-line line {
      stroke: black;
    }
    .y-guide-1 {
      stroke: #bbb;
      stroke-width: 2px;
    }
    .title text {
      font-size: 2rem;
    }
    .legend text {
      font-size: 0.9rem;
    }
    .x-title text {
      font-size: 1rem;
    }
    .x-title line {
      stroke: #333;
      stroke-width: 1px;
    }
    text.x-main-title {
      font-size: 1.3rem;
      font-weight: bold;
    }
    text.x-sub-title {
      font-style: italic;
      font-size: 1rem;
    }
    circle {
      stroke-width: 3px;
    }
  
    // component styles
    .info {
      span {
        font-weight: bold;
      }
    }
    .no-point {
      width: 350px;
      height: 400px;
      padding: 14px;
    }
    .window {
      height: 550px;
      width: 350px;
    }
  
    .sets {
      & button {
        border-radius: 3px;
        border: 1px solid #999;
        padding: 2px 6px;
        margin: 2px;
      }
      button.active {
        color: white;
        background-color: darkblue;
      }
      max-height: 826px;
    }
  
    .window {
      height: 675px;
    }
  
    .hide-point {
      display: none;
    }
    .group-name {
      cursor: pointer;
    }
    [hidden] {
      display: none;
    }
    .single {
      padding-left: 38px;
    }
    .error-msg {
      position: absolute;
      left: 50%;
      top: 40%;
      transform: translate(-50%, -50%);
      color: red;
    }
  
    .nodata {
      min-height: calc(100vh - 538px);
    }
    svg {
      background-color: white;
    }
    svg.legend {
      background-color: #f5f5f5;
    }
  
    .lineage-btn {
      line-height: 0.7rem;
      background-color: white;
      border-radius: 6px;
      padding: 0.4rem;
      margin: 0.25rem;
      font-size: 1rem;
      border: 1px solid #d5ccdd;
    }
  
    .lineage-btn.active {
      background-color: #944e97;
      color: white;
    }
  
    .p-2-5 {
      padding: 0.8rem;
    }
    .chart-header {
      padding-top: 2rem;
    }
  
    .neighbor-card {
      background-color: #f3f3f3;
    }
    .hover {
      opacity: 0.15;
    }
    .empty-msg {
      font-size: 1rem;
      font-weight: bold;
    }
    .lineage-loader {
      width: 800px;
      height: 840px;
    }
    app-loader {
      top: 30%;
      left: 14%;
      position: relative;
    }
    .backdrop {
      background-color: #fff;
      opacity: 0.6;
      width: 800px;
      top: 0;
      position: absolute;
      height: 100%;
    }
    .center-container {
      width: 800px;
    }
    .chart-footer {
      width: 100%;
      height: 50px;
      background-color: #676767;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .v-class {
      border: 1px solid red;
      border-radius: 4px;
      color: red;
      padding: 3px;
      margin: 0 10px;
    }
    .ncats-img {
      width: 164px;
    }
    .today {
      color: white;
      padding: 20px;
      font-size: 0.95rem;
    }
    .variant-list {
      width: 1474px;
      flex-wrap: wrap;
    }
  
    .selected-drug {
      fill: #99ae61;
      stroke: #99ae61;
      font-weight: bold;
    }
    line.selected-drug {
      stroke-width: 2px;
    }
  
    .beeswarm svg {
      margin-top: 30px;
      border-top: 15px solid #e9e9e9;
    }
  
    .beeswarm svg.selected-top-border {
      margin-top: 30px;
      border-top: 15px solid #c7df83;
    }
    .b-title {
      font-size: 1.2rem;
      font-weight: bold;
    }
    .b-title-drug {
      fill: #99ae61;
      font-size: 1.7rem;
      color: #99ae61;
    }
    .b-title-no-drug {
      fill: #bcbcbc;
      font-size: 1.7rem;
    }
    .banner-warning {
      width: 94%;
      padding-left: 60px;
      padding-right: 30px;
      b {font-weight: bold;}
      .warning {
        color: white;
        .triangle {
          font-size: 1.4rem;
        }
      }
      display: flex;
      background-color: #EBCDEC;
    }
    .filter-arrow{
      width: 50px;
      height: 75px;
      position: absolute;
      bottom: -55px;
      left: 0px;
    }
    .banner-container {
      position: relative;
      z-index: 1;
    }
  }
  
  foreignobject.mouseover {
    filter: drop-shadow(0px 10px 10px #888);
  
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) and (display: flow-root) {
        .popup-container {
          border: 1px solid #333;
        }
      }
    }
    .popup-container {
      padding: 10px;
      background-color: white;
    }
    .t-name { font-weight: bold; font-size: 1.2rem}
    .c-name { font-style: italic; font-size: 1rem; border-bottom: 1px solid black;  margin-bottom: 5px; padding-bottom: 5px; }
    .o-names { font-weight: bold; font-size: 1rem;}
  }
  
  foreignobject.expanded {
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) and (display: flow-root) {
        .popup-container {
          border: 1px solid #333;
        }
      }
    }
    .popup-container {
      padding: 10px;
    }
    .t-name { font-weight: bold; font-size: 1.7rem}
    .c-name { font-style: italic; font-size: 1.2rem; font-style: italic; margin-bottom: 5px; padding-bottom: 5px; }
    .o-names { font-weight: bold; font-size: 1rem;}
  }


  .slice-variant {
    display: flex;
  }
  .slice-variant div {
    padding-right: 20px;
  }
  .slice-variant > div:nth-child(2) {
    flex-grow: 1;
  }

  .activity-points {
    display: flex;
  }
  .circulating {
    display: flex;
  }
  .slice-variant div.l-color {
    padding-right: 5px;
  }
  .time-points-container {
    padding-top: 20px;
  }
  .activity-loader{
    width: 500px;
    position: relative;
    height: 480px;

  }
  .loader-container {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }
  rect.selected{
    stroke: black;
    stroke-width: 2px;
  }
  .selected-week-var {
    border: black solid 2px;
  }

  .lineage-loader-container {
    position: absolute;
    top: 200px;
  }
  .chart-content {
    position: relative;
    min-height: 500px;
  }
  .circulating-container {
    display: flex;
  }
  .circulating-legend {
    left: -50px;
    position: relative;
    background: white;
    padding-left: 10px;
  }
  .slice-variant.selected {
    background-color: #ee9;
    
  }
  .proportion-header {
    font-size: 22px;
  }
  .week-of {
    font-weight: bold;
    font-size: 14px;
  }
  .week-label {
    font-size: 14px;
  }
app-heatmap {
  .chart {
    position: relative;
    min-height: 1444px;
  }
  [hidden] {
    display: none;
  }
  .show-colors-btn {
    margin-top: 20px;
    margin-right: 5px;
  }

  .no-data {
    background: linear-gradient(
        45deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 43%,
        #fff 45%,
        #fff 55%,
        rgba(0, 0, 0, 0) 57%,
        rgba(0, 0, 0, 0) 100%
      ),
      linear-gradient(135deg, #f56b00 0%, #f56b00 43%, #fff 45%, #fff 55%, #f56b00 57%, #f56b00 100%);
  }

  .x-axis text {
    font-weight: bold;
  }
  .x-axis text,
  .y-axis text {
    cursor: pointer;
  }
  .x-axis text {
    color: #888;
  }
  .x-axis line {
    stroke: #ccc;
  }
  svg {
    margin-right: 20px;
    margin-top: 20px;
  }
  rect {
    stroke: #bbb;
    stroke-width: 1px;
  }
  .class-title text {
    font-size: 1.1rem;
    font-weight: bold;
  }

  .hover-square {
    stroke: black;
    opacity: 1;
    stroke-width: 3px;
  }

  foreignobject {
    filter: drop-shadow(0px 10px 10px #111);

    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) and (display: flow-root) {
        .popup-container {
          border: 1px solid #333;
        }
      }
    }
    .popup-container {
      padding: 10px;
      background-color: white;
    }
    .close-popup {
      float: right;
    }
    .popup-title {
      font-weight: bold;
      font-size: 0.9rem;
    }
    .counts {
      font-weight: bold;
      font-style: italic;
      span {
        font-size: 0.9rem;
      }
    }
    .browse {
      margin: 3px;
    }
  }
  .selected rect {
    stroke: rgb(44, 238, 57);
    stroke-width: 4px;
  }
  svg {
    background-color: #f5f5f5;
  }
  .legend {
    position: absolute;
    top: -120px;
    left: 423px;
  }
  .density {
    position: absolute;
    left: 193px;
    top: -58px;
    border-color: #7a0177;
    color: #7a0177;
  }
}

import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {RWEForestPlotQuery} from '../models/dtos/rweforestplot/rweforestplot-ql';

@Injectable({
  providedIn: 'root'
})
export class RWEForestPlotQL extends Query<RWEForestPlotQuery, {}> {
  override document = gql`
    {
      getAllRealworldevidenceforestplots {
        id
        reviewer
        covidenceNb
        dataTitle
        firstAuthor
        publicationDate
        dataSourceType
        studyCountry
        studyStartDate
        studyEndDate
        randomizedControlledTrial
        startingCohortHospitalized
        drug1
        drug1Id
        drug1Class
        drug1N
        drug1Time
        drug2
        drug2Id
        drug2Class
        drug2N
        drug2Time
        controlOrComparator
        comparatorName
        controlN
        totalPopulationN
        endpoint
        endpointPriority
        endpointComposite
        otherEndpointComposite
        endpointDescription
        outcomeReadoutType
        outcomeDrugN
        outcomeDrugReadout
        outcomeControlOrComparatorN
        outcomeControlOrComparatorReadout
        outcomeSubgroupVariant
        outcomeSubgroupVariantWho
        outcomeSubgroupComorbidities
        outcomeSubgroupVaccinationStatus
        outcomeSubgroupAgeYears
        outcomeSubgroupOther
        compositeEndpoint
        hospitalizationEndpoint
        mortalityEndpoint
        compositeOther
        metricNuance
        outcomeMetricType
        graphPosition
        outcomeMetric
        outcomeMetricLowerCi
        outcomeMetricUpperCi
        outcomeMetricLowerCiCalc
        outcomeMetricUpperCiCalc
        outcomeMetricPValue
        outcomeMetricTimepointDays
        hospitalizationTimepointDays
        mortalityTimepointDays
        pmid
        pmcid
        dataSource
        dosage
        abbreviatedSummary
        inclusionCriteriaMethods
        exclusionCriteriaMethods
        methodsStatsAnalysis
        platform
        notes
        studyLocation
        cohortUniqueness
      }
    }
  `;
}

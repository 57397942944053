.popup{
    position: absolute;
}

.innerBarLabel {
  font-size: 0.6rem;
  alignment-baseline: middle;
}

foreignobject.mouseover {
    filter: drop-shadow(0px 10px 10px #888);
  
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) and (display: flow-root) {
        .popup-container {
          border: 1px solid #333;
        }
      }
    }
    .popup-container {
      padding: 10px;
      background-color: white;
    }
    .t-name { font-weight: bold; font-size: 0.8rem}
  }
  
  foreignobject.expanded {
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) and (display: flow-root) {
        .popup-container {
          border: 1px solid #333;
        }
      }
    }
    .popup-container {
      padding: 10px;
    }
    .t-name { font-weight: bold; font-size: 1.7rem}
  }
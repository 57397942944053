import {Component, OnInit, ElementRef} from '@angular/core';
import * as d3 from 'd3';
import {ICellRendererParams} from 'ag-grid-community';

interface CustomCellRendererParams extends ICellRendererParams {
  plotType?: string; // Optional because you might not pass it every time
}

@Component({
  selector: 'app-d3-cell-renderer',
  templateUrl: './d3-cell-renderer.component.html',
  styleUrls: ['./d3-cell-renderer.component.scss']
})
export class D3CellRendererComponent implements OnInit {
  private params: CustomCellRendererParams | undefined;
  private value: any;
  private additionalData: any;
  private metricColorMap: any;
  private paddingLeft = 10; // Increased left padding to ensure lower CI endpoints are not cut off
  private paddingRight = 10; // Right padding
  private globalMinCI = 0.01; // Set a default minimum
  private globalMaxCI = 100; // Set a default maximum
  private width = 340; // Set maximum width
  private plotType: string = 'ratios'; // default to 'ratios'
  private logScale: any;

  constructor(private hostElement: ElementRef) {}

  ngOnInit(): void {
    // Calculate the global min/max CI across all data points
    this.calculateGlobalMinMaxCI();
    // Initial render, if value and additionalData are set at this time
    if (this.value && this.additionalData) {
      this.renderD3Chart(this.value, this.additionalData, this.metricColorMap);
    }

    // Remove the row borders
    const rowElement = this.hostElement.nativeElement.closest('.ag-row');
    if (rowElement) {
      rowElement.style.border = 'none';
    }
  }

  agInit(params: CustomCellRendererParams): void {
    this.params = params;
    this.value = params.value;
    this.metricColorMap = params.context.metricColorMap;
    this.additionalData = this.getAdditionalData(params);

    // Check if a plotType is provided
    if (this.params && this.params.plotType) {
      this.plotType = this.params.plotType;
    }

    // Adjust scale if it's for 'differences'
    if (this.plotType === 'differences') {
      // Use linear scale for differences with a fixed range from -20 to +20
      this.logScale = d3
        .scaleLinear()
        .domain([-20, 20]) // Differences should be plotted between -20 and +20
        .range([this.paddingLeft, this.width - this.paddingRight]);
    } else {
      // Log scale for ratios
      this.logScale = d3
        .scaleLog()
        .domain([Math.max(0.01, this.globalMinCI * 0.9), this.globalMaxCI * 1.1]) // Padding for ratios
        .range([this.paddingLeft, this.width - this.paddingRight]);
    }

    // Remove padding and margin from cell
    const cellElement = this.hostElement.nativeElement.closest('.ag-cell');
    if (cellElement) {
      cellElement.style.paddingLeft = '10px'; // Adjust padding to match the header
      cellElement.style.paddingRight = '10px';
      cellElement.style.margin = '0';
    }

    // Render with delay to ensure proper width calculation
    setTimeout(() => {
      this.renderD3Chart(this.value, this.additionalData, this.metricColorMap);
    }, 100); // Add a delay here as well
  }

  refresh(params: ICellRendererParams): boolean {
    const newAdditionalData = this.getAdditionalData(params);
    if (params.value !== this.value || JSON.stringify(this.additionalData) !== JSON.stringify(newAdditionalData)) {
      this.value = params.value;
      this.additionalData = newAdditionalData;
      this.renderD3Chart(this.value, this.additionalData, this.metricColorMap);
    }
    return true;
  }

  private getAdditionalData(params: ICellRendererParams) {
    return {
      lowerCI: params.data?.outcomeMetricLowerCi,
      upperCI: params.data?.outcomeMetricUpperCi,
      pValue: params.data?.outcomeMetricPValue
    };
  }

  private calculateGlobalMinMaxCI(): void {
    // Iterate over all data to find the global min and max CI
    d3.selectAll('.ag-cell').each((d: any) => {
      const lowerCI = d?.outcomeMetricLowerCi;
      const upperCI = d?.outcomeMetricUpperCi;
      if (lowerCI !== undefined && lowerCI < this.globalMinCI) {
        this.globalMinCI = lowerCI;
      }
      if (upperCI !== undefined && upperCI > this.globalMaxCI) {
        this.globalMaxCI = upperCI;
      }
    });
  }

  private renderD3Chart(value: any, additionalData: any, metricColorMap: any): void {
    const element = this.hostElement.nativeElement.querySelector('.d3-container');
    d3.select(element).selectAll('*').remove(); // Clear the container first

    const data = additionalData;
    const height = 50; // Set the height of the SVG

    const svg = d3
      .select(element)
      .append('svg')
      .attr('width', this.width)
      .attr('height', height)
      .style('border', 'none');

    // Define the scale for "ratios" and "differences" plots
    let scale;
    if (this.plotType === 'ratios') {
      // Logarithmic scale for ratios
      scale = d3
        .scaleLog()
        .domain([Math.max(0.01, this.globalMinCI * 0.9), this.globalMaxCI * 1.1])
        .range([this.paddingLeft, this.width - this.paddingRight]);
    } else if (this.plotType === 'differences') {
      // Check if this is a valid metric type for the differences plot
      const validTypes = ['Absolute Risk Difference', 'Difference (±SE) from Placebo', 'Difference from Placebo'];
      const metricType = this.params?.data?.outcomeMetricType;

      // Linear scale for differences
      scale = d3
        .scaleLinear()
        .domain([-20, 20])
        .range([this.paddingLeft, this.width - this.paddingRight]);

      // Draw the center vertical dashed line at 0 for differences plot
      svg
        .append('line')
        .attr('x1', scale(0) - 10)
        .attr('x2', scale(0) - 10)
        .attr('y1', 0)
        .attr('y2', height)
        .attr('stroke', 'black')
        .attr('stroke-dasharray', '3, 3');

      // Exit if the metric type is not valid for the differences plot
      // - do this last so that the dashed line is always drawn
      if (!validTypes.includes(metricType)) {
        return;
      }
    }

    // Ensure the scale is initialized properly
    if (!scale) {
      console.error('Scale is not defined.');
      return;
    }

    // Determine the color based on outcomeMetricType
    const metricType = this.params?.data?.outcomeMetricType;
    const dotcolor = metricColorMap[metricType as keyof typeof metricColorMap] || 'black'; // Fallback to black if no match

    // Draw the vertical dashed line for the ratios plot at x = 1.0
    if (this.plotType === 'ratios') {
      svg
        .append('line')
        .attr('x1', scale(1.0) - 10) // Fine-tune the alignment here
        .attr('x2', scale(1.0) - 10) // Shift by 0.5 or 1 pixel to align perfectly
        .attr('y1', 0)
        .attr('y2', height)
        .attr('stroke', 'black')
        .attr('stroke-dasharray', '3, 3');
    }

    // Check if valid data is available
    if (
      value == null ||
      data.lowerCI == null ||
      data.upperCI == null ||
      (this.plotType === 'ratios' && value < 0) || // Exclude negative values for ratios
      (this.plotType === 'ratios' && data.lowerCI < 0) ||
      (this.plotType === 'ratios' && data.upperCI < 0)
    ) {
      return;
    }

    // Draw the confidence interval line
    svg
      .append('line')
      .attr('x1', scale(data.lowerCI))
      .attr('x2', scale(data.upperCI))
      .attr('y1', height / 2)
      .attr('y2', height / 2)
      .attr('stroke', 'black')
      .attr('stroke-width', 2);

    // Draw the point estimate
    svg
      .append('circle')
      .attr('cx', scale(value))
      .attr('cy', height / 2)
      .attr('r', 7)
      .attr('fill', dotcolor); // Set your dot color here or use dotcolor variable

    // Draw vertical lines at the CI endpoints (left and right)
    svg
      .append('line')
      .attr('x1', scale(data.lowerCI))
      .attr('x2', scale(data.lowerCI))
      .attr('y1', height / 2 - 5)
      .attr('y2', height / 2 + 5)
      .attr('stroke', 'black')
      .attr('stroke-width', 2);

    svg
      .append('line')
      .attr('x1', scale(data.upperCI))
      .attr('x2', scale(data.upperCI))
      .attr('y1', height / 2 - 5)
      .attr('y2', height / 2 + 5)
      .attr('stroke', 'black')
      .attr('stroke-width', 2);
  }
}

import {Component, Inject, OnInit} from '@angular/core';
import {DatasetMetadataApiService} from '../../../services/api/dataset-metadata-api/dataset-metadata-api.service';
import * as CONST from '../../../constants/api-constants';
import {API_URLS, Endpoints} from '@odp/shared';
import {InvitroZipApiService} from '../../../services/api/invitro-zip-api/invitro-zip-api.service';
import {forkJoin} from 'rxjs';
@Component({
  selector: 'app-hero-header',
  templateUrl: './hero-header.component.html',
  styleUrls: ['./hero-header.component.scss']
})
export class HeroHeaderComponent {
  public show = true;
  public recordCount!: string;
  public dataSourceCount!: number;
  public lastUpdatedTxt!: string;
  public dataLoading = true;
  public variantBaseUrl: string | null = null;
  public zipUrl: string | null = null;

  constructor(
    private variantApi: DatasetMetadataApiService,
    @Inject(API_URLS) private configuration: Endpoints,
    private zipApi: InvitroZipApiService
  ) {
    this.variantBaseUrl = this.configuration.covid19ApiUrl;
    const metaData$ = this.variantApi.getVariantDatasetMeta();
    this.zipUrl = CONST.INVITRO_ZIP_URL;
    forkJoin([metaData$]).subscribe(([result]) => {
      this.lastUpdatedTxt = this.getUpdateText(result.data.updatedDate);
      this.dataSourceCount = result.data.count;
      this.dataLoading = false;
    });
    this.variantApi.getRecordCount().subscribe((result) => {
      this.recordCount = result.data.count;
    });
  }
  private getUpdateText(dateStr: string) {
    const today = new Date().getTime();
    const updatedTime = new Date(dateStr).getTime();
    const days = Math.ceil((today - updatedTime) / (1000 * 60 * 60 * 24));
    if (days === 0) return 'today';
    if (days === 1) return 'yesterday';
    return days.toString() + ' days ago';
  }
}

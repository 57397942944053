import {HttpClient, HttpParams, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {map, Observable, of, throwError} from 'rxjs';
import {catchError, timeout} from 'rxjs/operators';
import {VariantMapperService} from '../../mappers/variant-mapper/variant-mapper.service';
import {VariantDatasetQL} from '../../../queries/dataset-query';
import {OosDataset} from '../../../models/dtos/oos/oos-data';
import {VariantDataset} from '../../../models/dtos/variant/dataset';
import {DatasetFilters} from '../../../models/dtos/variant/dataset-filters';
import {OosDatasetQL} from '../../../queries/oos-query';
import {TherapeuticActivityQL} from '../../../queries/therapeutic-activity-query';
import {TherapeuticActivity} from '../../../models/dtos/therapeutics/therapeutic-activity';
import {API_URLS, Endpoints} from '@odp/shared';
import {HttpLink} from 'apollo-angular/http';
import {InMemoryCache} from '@apollo/client/core';
import {Apollo} from 'apollo-angular';
import {GRAPHQL_ENDPOINT_KEY} from '../../../constants/api-constants';
import {OosQuery} from '../../../models/dtos/oos/oos-data-ql';

interface ApiResponse {
  data: any[]; // Adjust `any` to a more specific type if possible
  total: number;
  num_pages: number;
  current_page: number;
}

@Injectable({
  providedIn: 'root'
})
export class DatasetsApiService {
  private variantBaseUrl!: string;
  private variantRestUrl!: string;
  private datasetsViewUrl!: string;
  private DEFAULT_PAGE_SIZE = 50;
  configService: any;
  constructor(
    private therapeuticActivityQL: TherapeuticActivityQL,
    private httpClient: HttpClient,
    httpLink: HttpLink,
    @Inject(API_URLS) private configuration: Endpoints,
    private apollo: Apollo,
    private oosDataQL: OosDatasetQL,
    private datasetQL: VariantDatasetQL,
    private variantMapper: VariantMapperService
  ) {
    this.variantRestUrl = configuration.variantApiUrl;
    this.variantBaseUrl = configuration.graphqlEndpoint;
    this.datasetsViewUrl = `${this.variantRestUrl}/route/variant/dataset/variant3-datasets`;

    const uri = configuration.graphqlEndpoint;
    if (!apollo.use(GRAPHQL_ENDPOINT_KEY)) {
      apollo.createNamed(GRAPHQL_ENDPOINT_KEY, {
        link: httpLink.create({uri}),
        cache: new InMemoryCache()
      });
    }
  }

  public getOosDataset(): Observable<OosDataset[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<OosQuery>({query: this.oosDataQL.document})
      .pipe(
        map((result: {data: OosQuery}) => {
          return this.variantMapper.mapToOosDatasets(result.data.variantooss.edges);
        })
      );
  }

  public getVariantDatasets(requestBody: any): Observable<ApiResponse> {
    const url = this.datasetsViewUrl;

    // note the requestBody contains the page, page_size, id_list, and filters
    return this.httpClient
      .post<ApiResponse>(url, requestBody, {
        headers: {'Content-Type': 'application/json'}
      })
      .pipe(
        map((DSresult: ApiResponse) => {
          // Ensure the data mapping happens here, and the mapped data still includes total, num_pages, etc.
          DSresult.data = this.variantMapper.mapToDatasets(DSresult.data as VariantDataset[]); // Ensure the mapping only affects the data array
          return DSresult; // Return the whole ApiResponse object
        }),
        catchError((error) => {
          console.error('Error fetching variant datasets:', error);
          return of({data: [], total: 0, num_pages: 0, current_page: 0}); // Return a default ApiResponse object
        })
      );
  }

  public getDatasetFilters(): Observable<{data: DatasetFilters}> {
    return this.httpClient.get<{data: DatasetFilters}>(`${this.variantRestUrl}/route/variant/dataset/filters`);
  }

  public getTherapeuticActivity(reportNumber: string): Observable<TherapeuticActivity[]> {
    const query = `reportNumber==${reportNumber}`;
    return this.therapeuticActivityQL
      .fetch({keyFilter: query})
      .pipe(map((result) => this.variantMapper.mapToTherapeuticActivity(result.data.variant3.edges)));
  }
}

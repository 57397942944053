import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {API_URLS, Endpoints} from '@odp/shared';
import {
  TherapeuticGlossary,
  TherapeuticGlossaryEntry,
  TherapeuticGlossaryQuery
} from '../../../models/dtos/therapeutics/therapeutic-glossary-ql';
import {TherapeuticGlossaryQL} from '../../../queries/therapeutic-glossary';
import {Apollo} from 'apollo-angular';
import {GRAPHQL_ENDPOINT_KEY} from '../../../constants/api-constants';
import {HttpLink} from 'apollo-angular/http';
import {InMemoryCache} from '@apollo/client/core';
import {SingleTherapeuticGlossaryQuery} from '../../../models/dtos/therapeutics/single-therapeutic-glossary-ql';
import {HeterologousBoosterQL} from '../../../queries/heterologousbooster-query';

@Injectable({
  providedIn: 'root'
})
export class TherapeuticGlossaryApiService {
  private variantBaseUrl!: string;
  configService: any;
  constructor(
    private httpClient: HttpClient,
    httpLink: HttpLink,
    @Inject(API_URLS) private configuration: Endpoints,
    private apollo: Apollo,
    private therapeuticGlossaryQL: TherapeuticGlossaryQL,
    private heterologousBoosterQL: HeterologousBoosterQL
  ) {
    this.variantBaseUrl = configuration.variantApiUrl;

    const uri = this.configuration.graphqlEndpoint;
    if (!apollo.use(GRAPHQL_ENDPOINT_KEY)) {
      apollo.createNamed(GRAPHQL_ENDPOINT_KEY, {
        link: httpLink.create({uri}),
        cache: new InMemoryCache()
      });
    }
  }

  public getTherapeuticGlossary(): Observable<TherapeuticGlossary[]> {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<TherapeuticGlossaryQuery>({query: this.therapeuticGlossaryQL.document})
      .pipe(
        map((result: {data: {getTherapeuticGlossaryAll: any}}) => {
          return this.mapTherapeuticGlossaryToEntry(result.data.getTherapeuticGlossaryAll);
        })
      );
  }

  public mapTherapeuticGlossaryToEntry(glossaryEntry: TherapeuticGlossaryEntry[]): TherapeuticGlossary[] {
    return glossaryEntry.map((e) => {
      const variantDrug = e.variantDrug;
      const glossary: TherapeuticGlossary = {
        id: e.id,
        glossaryPdb: e.glossaryPdb,
        glossaryMoa: e.glossaryMoa,
        glossarySmiles: e.glossarySmiles,
        glossaryStatus: e.glossaryStatus,
        glossarySummary: e.glossarySummary,
        drugId: e.drugId,
        drugName: variantDrug?.drugName,
        drugClass: variantDrug?.drugClass,
        drugCompany: variantDrug?.drugCompany,
        unii: variantDrug?.unii,
        alias1: variantDrug?.alias1,
        activityPointCount: e.activityPointCount,
        glossaryCitelineDrugId: e.glossaryCitelineDrugId,
        glossarySmallmoleucleType: e.glossarySmallmoleucleType,
        glossaryAntibodyType: e.glossaryAntibodyType,
        glossaryFdastatus: e.glossaryFdastatus
      };
      return glossary;
    });
  }

  public getSingleTherapeuticGlossary(id: any) {
    return this.apollo
      .use(GRAPHQL_ENDPOINT_KEY)
      .query<SingleTherapeuticGlossaryQuery>({
        query: this.therapeuticGlossaryQL.getSingleTherapeuticGlossary,
        variables: {id: id}
      })
      .pipe(map((result: {data: {therapeuticglossary: any}}) => result.data.therapeuticglossary));
  }
}

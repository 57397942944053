import {Injectable} from '@angular/core';
import {gql, Query} from 'apollo-angular';
import {AnimalModelResponse} from '../../models/dtos/animal';

@Injectable({
  providedIn: 'root'
})
export class AnimalModelsQL extends Query<AnimalModelResponse, {}> {
  override document = gql`
    query getAnimalModel {
      smallanimalmodels {
        modelId
        species
        modification
        nomenclature
        vaccines
        antivirals
        neutralizing
        otherTherapies
        infectivity
        transmission
        id
        diseaseEnhancement
        diseaseManifestationAndPathology
        extentOfDisease
        updated
      }
      nonhumanprimatemodels {
        modelId
        species
        geographicOrigin
        routeOfExposure
        vaccines
        antivirals
        neutralizingAbs
        otherTherapies
        infectivity
        transmission
        diseaseEnhancement
        diseaseManifestationAndPathology
        extentOfDisease
        updated
      }
    }
  `;
}

import {Component, OnInit} from '@angular/core';
import {ITooltipAngularComp} from 'ag-grid-angular';
import {ITooltipParams} from 'ag-grid-community';

@Component({
  selector: 'app-rwe-forestplot-tooltip-component',
  template: ` <div class="custom-tooltip" [style.background-color]="color">
    <p>
      <span> {{ displayValue }} </span>
    </p>
  </div>`,
  styles: [
    `
      :host {
        position: absolute;
        /* width: 150px; - dont put this here or tooltip is too narrow (TT) */
        height: 70px;
        pointer-events: none;
        transition: opacity 1s;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip p {
        margin: 5px;
      }
      .custom-tooltip {
        max-width: 400px; /* Adjust the max-width as needed */
        word-wrap: break-word; /* Allow long words to break */
        white-space: pre-wrap; /* Wrap text to multiple lines */
      }
      .custom-tooltip p:first-of-type {
        font-weight: bold;
      }
    `
  ]
})
export class RWEForestPlotReportTooltipComponent implements ITooltipAngularComp {
  private params!: {color: string} & ITooltipParams;
  public data: any;
  public color!: string;
  public displayValue!: string;
  constructor() {
    this.color = 'white'; // Default color
    this.displayValue = ''; // Default display value
  }

  agInit(params: {color: string} & ITooltipParams): void {
    this.params = params;
    this.displayValue = params.value;
    this.color = this.params.color || 'white';
  }

  refresh(params: {color: string} & ITooltipParams): boolean {
    this.params = params;
    this.displayValue = params.valueFormatted || params.value || 'No value';
    return true;
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {forkJoin} from 'rxjs';
import {KeyValue} from '@angular/common';
import {InVivoApiService} from '../../../services/api/in-vivo-api/in-vivo-api.service';
import {DateRange} from '../../../models/view-models/date-range';
import {InVivoApiFilters} from '../../../models/dtos/in-vivo/invivo-filters';
import {InVivoPoint} from '../../../models/dtos';

@Component({
  selector: 'app-vivo-filters',
  templateUrl: './vivo-filters.component.html',
  styleUrls: ['./vivo-filters.component.scss']
})
export class VivoFiltersComponent implements OnInit {
  public filterValues: Map<string, string[]> = new Map<string, string[]>();
  public filterExpanded: Map<string, boolean> = new Map<string, boolean>();
  public selectedColFilters: Map<string, string[]> = new Map<string, string[]>();
  public dateExpanded = false;
  @Output() filtersChanged: EventEmitter<SelectedVivoFilters> = new EventEmitter<SelectedVivoFilters>();
  public dateRange!: DateRange;
  public dateRangeTxt!: string;
  public filterColumn: string[] = [
    'Test Species',
    'Viral Lineage',
    'Therapeutic Name',
    'Therapeutic Class',
    'Study Measures',
    'Study Type'
  ];

  constructor(private inVivoApi: InVivoApiService) {}

  ngOnInit(): void {
    const getInVivoFilters$ = this.inVivoApi.getInVivoPoints();
    forkJoin([getInVivoFilters$]).subscribe(([inVivoFilters]) => {
      this.initalizeSelectedFilters();
      this.mapApiFiltersToColumns(inVivoFilters);
    });
  }

  private mapApiFiltersToColumns(points: InVivoPoint[]) {
    this.initializeFilterEntries();

    const testSpeciesFilterOptions = [
      ...new Set(
        points
          .map((x: InVivoPoint) => x.model.trim())
          .filter((y: any) => !!y)
          .sort((a: string, b: string) => a.localeCompare(b))
      )
    ];

    const viralLineageFilterOptions = [
      ...new Set(
        points
          .map((x: InVivoPoint) => x.variants.trim())
          .filter((y: any) => !!y)
          .sort((a: string, b: string) => a.localeCompare(b))
      )
    ];

    const therapeuticNameFilterOptions = [
      ...new Set(
        points
          .map((x: InVivoPoint) => x?.therapeuticAgents?.trim() || '')
          .filter((y: string) => !!y)
          .sort((a: string, b: string) => a.localeCompare(b))
      )
    ];

    const therapeuticClassFilterOptions = [
      ...new Set(
        points
          .map((x: InVivoPoint) => x?.therapeuticClasses?.trim() || '')
          .filter((y: string) => !!y)
          .sort((a: string, b: string) => a.localeCompare(b))
      )
    ];

    //Add studyMeasures
    const studyMeasures = [
      'Clinical Manifestations',
      'Viral Load',
      'Histopathology',
      'Neutralization',
      'Antibody Response',
      'Transmission',
      'Rechallenge'
    ];

    const studyTypeFilterOptions = [
      ...new Set(
        points
          .map((x: InVivoPoint) => x.studyType.trim())
          .filter((y: any) => !!y)
          .sort((a: string, b: string) => a.localeCompare(b))
      )
    ];

    this.setColumnValuesFromApi('Test Species', testSpeciesFilterOptions);
    this.setColumnValuesFromApi('Viral Lineage', viralLineageFilterOptions);
    this.setColumnValuesFromApi('Therapeutic Name', therapeuticNameFilterOptions);
    this.setColumnValuesFromApi('Therapeutic Class', therapeuticClassFilterOptions);
    this.setColumnValuesFromApi('Study Measures', studyMeasures);
    this.setColumnValuesFromApi('Study Type', studyTypeFilterOptions);
    this.setColumnValuesFromApi(
      'Reported Date Range',
      points.map((x: any) => x.reported_date)
    );
  }

  private initializeFilterEntries() {
    for (const col of this.filterColumn) {
      this.filterValues.set(col, []);
      this.filterExpanded.set(col, false);
    }
  }

  private initalizeSelectedFilters() {
    for (const col of this.filterColumn) {
      this.selectedColFilters.set(col, []);
    }
  }

  private setColumnValuesFromApi(columnName: string, recordDB: string | any[]) {
    const dataList = this.filterValues.get(columnName);
    for (const vl of recordDB) {
      if (dataList) {
        dataList.push(vl);
      }
    }
  }
  public changeDate(dateInfo: any) {
    this.dateRange = dateInfo;
    this.emitFilters();
  }

  private emitFilters() {
    this.filtersChanged.emit({
      cols: this.selectedColFilters,
      dateRange: this.dateRange
    });
  }

  public toggleFilter(filterValue: string, column: string) {
    const dataList = this.selectedColFilters.get(column);
    if (!!dataList && dataList.includes(filterValue)) {
      const i = dataList.indexOf(filterValue);
      dataList.splice(i, 1);
      this.emitFilters();
      return;
    }
    if (!!dataList) {
      dataList.push(filterValue);
    }
    this.emitFilters();
  }

  public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };
}
export interface SelectedVivoFilters {
  cols: Map<string, string[]>;
  dateRange: DateRange;
}
